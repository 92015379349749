<template>
    <header class="w-full pt-10 flex items-center justify-center">
        <div class="w-full flex flex-col items-center text-center">
            <img :src="logo" width="300" alt="logo">
            <div class="max-w-[700px]">
                <h1 class="uppercase text-2xl tracking-tight font-black text-gray-600 mt-6">{{ title }}</h1>
                <h3 class="uppercase text-xl tracking-tight font-bold text-gray-500">{{subtitle}}</h3>
            </div>
        </div>
    </header>
</template>
<script>
import logo from "@/assets/img/logo.png";

export default {
    data() {
        return {
            logo
        }
    },
    props: {
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: false
        },
    },
    computed: {
    }
};
</script>
