<template>
  <vueRecaptcha v-show="showRecaptcha" :sitekey="siteKey"
    size="normal"
    theme="light"
    hl="es"
    :loading-timeout="loadingTimeout"
    @verify="recaptchaVerified"
    @expire="recaptchaExpired"
    @fail="recaptchaFailed"
    @error="recaptchaError"
    ref="vueRecaptcha">
  </vueRecaptcha>
</template>

<script>
import vueRecaptcha from 'vue3-recaptcha2';

export default {
    components: {vueRecaptcha},
    data() {
        return {
            showRecaptcha: true,
            loadingTimeout: 30000,
            siteKey: "6Ld1CrooAAAAAKunhN7WqOkPEE6_JBKtSougQoGZ",
        }
    },
    methods: {
        recaptchaVerified(response) {
            this.$emit('didRecaptchaVerified', response);
        },
        recaptchaExpired() {
            this.$refs.vueRecaptcha.reset();
            this.$emit('didRecaptchaExpired');
        },
        recaptchaFailed() {
            this.$emit('didRecaptchaFailed');
        },
        recaptchaError(reason) {
            this.$emit('didRecaptchaError', reason);
        }
    }
}
</script>