<template>
    <footer class="bg-black  w-full flex flex-col sm:flex-row items-center justify-between px-10 py-10">
        <img :src="logoDgcg" alt="Logo DGCG" width="70" height="70">
        <div class="text-center text-gray-200 font-bold text-md">
            <span>DGCG Honduras © {{ currentYear }} Todos los derechos reservados.</span>
        </div>
        <img :src="logoIp" alt="Logo IP" width="70" height="70">
    </footer>
</template>
<script>
import logoIp from "@/assets/img/logo_ip.png";
import logoDgcg from "@/assets/img/logo_dgcg.png";
export default {
    data() {
        return {
            logoIp,
            logoDgcg
        }
    },
    props: {

    },
    computed: {
        currentYear() {
            return new Date().getFullYear()
        }
    }
};
</script>
