import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {GEO_PROJ} from "@/constants";
import compassIcon from "@/assets/img/compass.png";
import logo from "@/assets/img/logo.png";
import logoIp from "@/assets/img/logo_ip.png";

export function convertMapToPDF(map, mapContainer) {
    document.body.style.cursor = 'wait'

    const format = 'a4';
    //const resolution = '100';
    const size = /** @type {module:ol/size~Size} */ (map.getSize());
    const width = size[0]
    const height = size[1]
    const ratio = height / width
    const viewResolution = map.getView().getResolution();
    const extent = map.getView().calculateExtent(size);
    const exportOptions = {
        useCORS: true,
        ignoreElements: function (element) {
            if (
                element.classList.contains('ol-control') ||
                element.classList.contains('geolayer-box') ||
                element.classList.contains('top-bar-header') ||
                element.classList.contains('cart-button') ||
                element.classList.contains('buttons-box')
            ) {
                return true;
            }
        }
    };

    map.once('rendercomplete', function () {
        html2canvas(mapContainer, exportOptions).then(function (canvas) {
            const pdf = new jsPDF('landscape', undefined, format);
            let pageWidth = pdf.internal.pageSize.getWidth();
            let pageHeight = pdf.internal.pageSize.getHeight();
            let heightRatio = pageWidth * ratio;

            // Función para agregar un rectángulo con el color de fondo
            function addColoredRect(x, y, width, height, r, g, b) {
                pdf.setFillColor(r, g, b);
                pdf.rect(x, y, width, height, 'F');
            }

            // Función para agregar una imagen al PDF
            function addImageToPDF(imageSource, x, y, width, height) {
                var image = new Image();
                image.src = imageSource;
                pdf.addImage(image, 'PNG', x, y, width, height);
            }

            // Función para agregar texto al PDF
            function addTextToPDF(text, x, y, size, textColor) {
                pdf.setFontSize(size);
                pdf.setTextColor(textColor.r, textColor.g, textColor.b);
                pdf.text(text, x, y);
            }

            // Fondo de la página
            addColoredRect(0, 0, pageWidth, pageHeight, 4, 19, 46);

            // Barra superior
            const topBarHeight = pageHeight * 0.08;
            addColoredRect(0, 0, pageWidth, topBarHeight, 255, 255, 255);

            // Logo
            addImageToPDF(logo, 2, 2, 45, 13.176504967855);

            // Logo IP
            addImageToPDF(logoIp, pageWidth * 0.95, 2, 13, 13);

            // Título
            pdf.setFont(undefined, 'bold')
            addTextToPDF('Dirección Nacional de Cartografía y Catastro', 55, 10, 16, { r: 70, g: 70, b: 70 });

            // Mapa
            addImageToPDF(canvas.toDataURL('image/jpeg'), 0, topBarHeight, pageWidth, heightRatio);

            // Footer
            addColoredRect(0, pageHeight - 50, pageWidth, 50, 136, 207, 225);

            pdf.setFont(undefined, 'normal')
            // Textos del footer
            addTextToPDF('Sistema de Coordenadas: World Geodetic System 1984', 10, pageHeight - 40, 12, { r: 0, g: 0, b: 0 });
            addTextToPDF(`Proyección: ${GEO_PROJ}`, 10, pageHeight - 33, 12, { r: 0, g: 0, b: 0 });
            let date = new Date();
            let currentDate = date;
            addTextToPDF(`Fecha: ${currentDate}`, 10, pageHeight - 26, 12, { r: 0, g: 0, b: 0 });
            addTextToPDF('Fuente: Direccion Nacional de Cartografia y Catastro', 10, pageHeight - 18, 12, { r: 0, g:0, b: 0 });
            addImageToPDF(compassIcon, pageWidth * 0.92, pageHeight - 40, 15, 21);

            // Texto de derechos de autor
            addColoredRect(0, pageHeight - 10, pageWidth, 10, 4, 19, 46);
            addTextToPDF(`DGCG Honduras © ${currentDate.getFullYear()} Todos los derechos reservados.`, 3, pageHeight - 4, 12, { r: 255, g: 255, b: 255 });

            // Guardar el PDF
            pdf.save('Reporte Mapa DGCG.pdf');
            document.body.style.cursor = 'default';

            map.updateSize();
            map.getView().setResolution(viewResolution);
        })
    })

    const printSize = [width, height]
    map.setSize(printSize)
    map.getView().fit(extent, {
        size: printSize
    })
}