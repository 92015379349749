<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="23.3105" height="22.1631">
     <g>
      <rect height="22.1631" opacity="0" width="23.3105" x="0" y="0"/>
      <path d="M8.83789 19.5044L14.4727 19.5044L14.4727 12.395C14.4727 11.9458 14.1797 11.6528 13.7305 11.6528L9.58984 11.6528C9.13086 11.6528 8.83789 11.9458 8.83789 12.395ZM5.01953 20.5103L18.2324 20.5103C19.6191 20.5103 20.4297 19.7192 20.4297 18.3521L20.4297 7.56104L18.8574 6.48682L18.8574 17.9517C18.8574 18.5864 18.5156 18.938 17.9004 18.938L5.35156 18.938C4.72656 18.938 4.38477 18.5864 4.38477 17.9517L4.38477 6.49658L2.8125 7.56104L2.8125 18.3521C2.8125 19.7192 3.62305 20.5103 5.01953 20.5103ZM0 9.64111C0 10.0415 0.3125 10.4224 0.839844 10.4224C1.11328 10.4224 1.33789 10.2759 1.54297 10.1099L11.3184 1.90674C11.5332 1.71143 11.7969 1.71143 12.0117 1.90674L21.7871 10.1099C21.9824 10.2759 22.207 10.4224 22.4805 10.4224C22.9395 10.4224 23.3105 10.1392 23.3105 9.67041C23.3105 9.37744 23.2129 9.18213 23.0078 9.00635L12.8418 0.461426C12.1191-0.153809 11.2207-0.153809 10.4883 0.461426L0.3125 9.00635C0.0976562 9.18213 0 9.4165 0 9.64111ZM17.9785 5.23682L20.4297 7.30713L20.4297 2.79541C20.4297 2.36572 20.1562 2.09229 19.7266 2.09229L18.6816 2.09229C18.2617 2.09229 17.9785 2.36572 17.9785 2.79541Z" fill="currentColor"/>
     </g>
    </svg>
</template>
<script>
export default {
    props: {
    }
};
</script>
