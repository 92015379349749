<template>
    <div class="flex flex-col justify-between bg-white border border-gray-200 rounded-xl overflow-hidden">
        <div class="relative w-full bg-gray-400 pb-2/3">
            <div class="absolute bottom-2 left-2 z-10 bg-white/60 text-sm text-gray-700 backdrop-blur shadow px-2 py-1 rounded">
                <p v-if="product.sale_type === 'physical'">
                    <strong>Fisico:</strong> L {{product.price}}
                </p>
                <p v-if="product.sale_type === 'both'">
                    <strong>Fisico: </strong> L {{product.price}}
                </p>
                <div v-if="product.sale_type !== 'physical'">
                    <p v-for="(file, file_key) in product.files" :key="file_key">
                        <strong> {{file.file_format}}: </strong> L {{file.price}}
                    </p>
                </div>
            </div>
            <img class="absolute w-full h-full object-cover z-0"
                 :src="`${baseUrl}${product.thumbnail_image}`"
                 alt="">
        </div>
        <div class="p-3 text-center">
            <h3 class="text-lg font-bold">{{product.title}}</h3>
            <p class="text-sm text-gray-600">{{product.edition}}</p>
            <p class="text-sm text-gray-600" v-if="product.metadata_link">
                <a :href="product.metadata_link" target="blank" class="link">Ver metadatos</a>
            </p>
        </div>
        <div class="w-full ">
            <FlatButton :no-rounded="true" color="sky" size="md" width="full" @click="$emit('onAdd')">
                <i class="fas fa-cart-plus"></i>
                Agregar al carrito
            </FlatButton>
        </div>
    </div>
</template>
<script>
import FlatButton from "@/components/controls/FlatButton.vue";
import {baseUrl} from "@/constants";

export default {
    components: {FlatButton},
    props: {
        product: {
            title: String,
            thumbnail_image: String,
            edition:String,
            serie: String,
            metadata_link: String,
            sale_type: String,
            file_format: String,
            price: String,
        },
    },
    data() {
        return {
            baseUrl,
        }
    }
};
</script>
