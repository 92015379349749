<template>
    <div class="mx-auto max-w-screen-sm px-8 text-center">
        <img :src="webMaintenanceImg" class="inline-block" width="300" alt="logo">
        <h2 class="text-lg h2 mb-4 font-bold">El sitio se encuentra temporalmente fuera de servicio debido a labores de mantenimiento.</h2>
        <p class="mb-4 text-lg text-gray-600 aos-init aos-animate lg:mb-12 sm:text-xl">Esperamos tu comprensión y te invitamos a regresar en unas horas o en los próximos días, cuando restauremos nuestros servicios.</p>
        <p class="mb-4 font-light text-gray-500 lg:mb-16 sm:text-l">Dirección General de Cartográfia y Catastro.</p>
    </div>
</template>
<script>
import webMaintenanceImg from "@/assets/img/web_maintenance_image.png";

export default {
    data() {
        return {
            webMaintenanceImg
        }
    },
    props: {
    },
    computed: {
    }
};
</script>
