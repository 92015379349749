<template>
    <div class="w-11 backdrop-blur text-black/70 rounded-xl shadow pointer-events-auto">
        <button title="Acercar" @click="$emit('didZoomIn')" class="h-10 w-full border-0 flex items-center justify-center bg-white/80 hover:bg-white/60  rounded-t-xl">
            <span class="text-lg font-bold">+</span>
        </button>
        <button title="Alejar" @click="$emit('didZoomOut')" class="h-10 w-full border-0 flex items-center justify-center bg-white/80 hover:bg-white/60 rounded-b-xl">
            <span class="text-lg font-bold">-</span>
        </button>
    </div>
</template>
<script>
export default {
    props: {

    }
};
</script>
