<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17.9785" height="17.998">
         <g>
          <rect height="17.998" opacity="0" width="17.9785" x="0" y="0"/>
          <path d="M3.06641 17.998L14.9121 17.998C16.9629 17.998 17.9785 16.9824 17.9785 14.9707L17.9785 3.04688C17.9785 1.03516 16.9629 0.0195312 14.9121 0.0195312L3.06641 0.0195312C1.02539 0.0195312 0 1.02539 0 3.04688L0 14.9707C0 16.9922 1.02539 17.998 3.06641 17.998ZM3.08594 16.4258C2.10938 16.4258 1.57227 15.9082 1.57227 14.8926L1.57227 3.125C1.57227 2.10938 2.10938 1.5918 3.08594 1.5918L14.8926 1.5918C15.8594 1.5918 16.4062 2.10938 16.4062 3.125L16.4062 14.8926C16.4062 15.9082 15.8594 16.4258 14.8926 16.4258Z" fill="currentColor" />
          <path d="M12.0508 11.416C12.4805 11.416 12.7734 11.084 12.7734 10.6348L12.7734 6.02539C12.7734 5.44922 12.4512 5.22461 11.9531 5.22461L7.32422 5.22461C6.86523 5.22461 6.57227 5.50781 6.57227 5.9375C6.57227 6.36719 6.875 6.65039 7.34375 6.65039L9.12109 6.65039L10.5664 6.49414L9.04297 7.91016L5.43945 11.5137C5.30273 11.6504 5.21484 11.8457 5.21484 12.041C5.21484 12.4805 5.49805 12.7637 5.92773 12.7637C6.16211 12.7637 6.34766 12.6758 6.48438 12.5391L10.0781 8.94531L11.4844 7.44141L11.3379 8.96484L11.3379 10.6543C11.3379 11.1133 11.6211 11.416 12.0508 11.416Z" fill="currentColor" />
         </g>
    </svg>
</template>
<script>
export default {
    props: {
    }
};
</script>
