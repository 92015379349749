<template>
    <div class="text-black/60 pointer-events-auto relative z-10">
        <input type="text" :class="inputClass"  class="h-11 w-52 placeholder-black/50 shadow rounded-xl bg-white/70 backdrop-blur px-3 outline-none" v-model="searchWord" v-on:input="searchLocation(searchWord)" placeholder="Buscar departamento o municipio">
        <div class="absolute top-11 left-0 w-full bg-white/70 backdrop-blur overflow-hidden rounded-b-xl">
            <ul class="w-full">
                <li v-for="(item, key) in searchResult" :key="key">
                    <button class="px-2 py-2 flex flex-col border-t border-t-white/10 w-full hover:bg-white/50" @click="$emit('didZoomToExtend', item.coordinates)">
                        <strong>
                            {{item.title}}
                        </strong>
                        <small>{{item.type + ' de ' +item.country}}</small>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import Api from "@/services/api";

export default {
    props: {
    },
    data() {
        return {
            searchWord: '',
            searchResult: [],
        }
    },
    computed: {
        inputClass(){
            return {
                'rounded-b-none': this.searchResult.length > 0,
            }
        }
    },
    methods: {
        async searchLocation (word){
            if (word.length < 3) {
                this.searchResult = [];
                return
            }

            const {error, errorMessage, data} = await Api.searchLocation(word)
            if (error) {
                this.showErrorMessage(errorMessage)
                return
            }
            this.searchResult = data
        },
        showErrorMessage(message) {
            this.$toast.open({
                message: message,
                type: 'error',
                position: 'top-right',
            });
        }

    }
};
</script>
