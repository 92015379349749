import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import VueToast from 'vue-toast-notification';
// styles
import OpenLayersMap from 'vue3-openlayers'
import 'vue3-openlayers/dist/vue3-openlayers.css'
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
import "@/assets/styles/style.css";
import 'vue-toast-notification/dist/theme-sugar.css';

// mouting point for the whole app

import App from "@/App.vue";

// layouts
import EmergencyGeoportalView from "@/views/EmergencyGeoportalView.vue";
import CartoProductView from "@/views/CartoProductView.vue";
import VueCreditCardValidation from 'vue-credit-card-validation';
import ShoppingFormView from "@/views/ShoppingFormView.vue";
import CadastralMaintenanceFormView from "@/views/CadastralMaintenanceFormView.vue";
import HomeView from "@/views/HomeView.vue";
import GeodeticPointFormView from "@/views/GeodeticPointFormView.vue";
import AerialPhotographyFormView from "@/views/AerialPhotographyFormView.vue";
import GeoportalView from "@/views/GeoportalView.vue";
import EducationCenterFormView from "@/views/EducationCenterFormView.vue";
// routes

const routes = [
  {
    path: "/inicio",
    component: HomeView,
  },
  {
    path: "/geoportal",
    component: GeoportalView,
  },
  {
    path: "/productos-cartograficos",
    component: CartoProductView,
  },
  {
    path: "/solicitud-punto-geodesico",
    component: GeodeticPointFormView,
  },
  {
    path: "/solicitud-mantenimiento-catastral",
    component: CadastralMaintenanceFormView,
  },
  {
    path: "/solicitud-vuelo-aerofotografico",
    component: AerialPhotographyFormView,
  },
  {
    path: "/solicitud-referencia-centro-educativo",
    component: EducationCenterFormView,
  },
  {
    path: "/formulario-de-compra/:cart_name",
    component: ShoppingFormView,
  },
  {
    path: "/emergencias-nacionales",
    component: EmergencyGeoportalView,
  },
  { path: "/:pathMatch(.*)*", redirect: "/inicio" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);
app.use(router);
app.use(VueToast);
app.use(OpenLayersMap);
app.use(VueCreditCardValidation);
app.mount("#app");
