<template>
<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-school" width="40" height="40" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6"></path>
   <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"></path>
</svg>
</template>
<script>
export default {
    props: {
    }
};
</script>
