<template>
    <main class="grow">
        <section>
            <div class="text-center pt-12 md:pt-20 pb-4">
                <TopHeader  :title="''"/>
                <h1 class="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4 aos-init aos-animate" data-aos="zoom-y-out">
                    Sistema de Ventanilla Electrónica
                    <div class="pl-2 pr-2">
                        <span class="pl-2 pr-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">Productos y Servicios</span>
                    </div>

                </h1>
                <div class="max-w-3xl mx-auto">
                    <p class="text-xl text-gray-600 mb-8 aos-init aos-animate" data-aos="zoom-y-out" data-aos-delay="150">
                        El sistema agrupa un conjunto de productos y servicios ofrecidos por la <strong>Dirección General de Cartografía y Geografía</strong>  para su adquisición y venta en línea.
                    </p>
                </div>
            </div>
        </section>
        <section class="relative ">
            <div v-if="services.length !== 0" class="absolute inset-0 lg:mt-0 bg-gray-900 pointer-events-none" aria-hidden="true"></div>
            <div class="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div class="py-8 md:py-16 ">
                    <div class="flex flex-wrap justify-center gap-6">
                        <router-link v-for="(service, key) in services" :key="key" :to="service.url" class=" w-80 relative flex flex-col items-center p-6 bg-white rounded shadow-xl hover:bg-sky-100 border-2 border-transparent hover:border-blue-500 cursor-pointer">
                            <dt class="mb-3 text-3xl font-extrabold p-1 rounded-lg" :class="getTailwindColor(service.color)">
                                <GeoporalIcon  v-if="service.code==='layer'" />
                                <CatalogueIcon v-if="service.code==='image'"/>
                                <CadastralIcon v-if="service.code==='cadastral_maintenance'"/>
                                <GeodesicIcon v-if="service.code==='geodetic_point'"/>
                                <SchoolIcon v-if="service.code==='education_center_point'"/>
                                <FlyIcon v-if="service.code==='aerial_photography'"/>
                                <EmergencyIcon v-if="service.code==='emergency_geoportal'"/>
                            </dt>
                            <h4 class="text-xl text-center font-bold leading-snug tracking-tight mb-1">{{ service.title }}</h4>
                            <p class="text-gray-600 text-center">{{ service.description }}</p>
                        </router-link>
                    </div>
                </div>
                <div class="py-4 md:py-6 mb-10" v-if="services.length === 0">
                    <WebUnderMaintenance />
                </div>
            </div>
        </section>
        <section class="relative" v-if="services.length !== 0" >
            <div class="max-w-6xl mx-auto px-4 sm:px-6">
                <div class="py-12 md:py-20">
                    <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                        <h2 class="text-lg h2 mb-4 uppercase">Con nuestros más de {{services.length}} servicios y productos activos.</h2>
                        <p class="text-lg text-gray-600 aos-init aos-animate" data-aos="zoom-y-out">Ofrecemos un catálogo de diversos recursos, como mapas en formato físico y digital a través de la <strong>Tienda Cartográfica</strong>, capas cartográficas mediante nuestros <strong>Geoportales</strong>, y la realización de diversas solicitudes mediante <strong>Formularios</strong>.</p>
                    </div>
                    <div class="max-w-sm md:max-w-4xl mx-auto grid gap-2 grid-cols-2 md:grid-cols-2">
                        <div class="flex items-center justify-center py-2 col-span-2 md:col-auto">
                            <img :src="logoGobGray" alt="Logo Gobierno" width="200" >
                        </div>
                        <div class="flex items-center justify-center py-2 col-span-2 md:col-auto">
                            <img :src="logoIpGray" alt="Logo Ip" width="200" >
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="max-w-6xl mx-auto px-4 sm:px-6">
                <div class="pb-12 md:pb-20">
                    <div class="relative bg-gray-900 rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl overflow-hidden aos-init aos-animate" data-aos="zoom-y-out">
                        <div class="absolute right-0 bottom-0 pointer-events-none hidden lg:block" aria-hidden="true">
                            <img :src="earthImg" alt="Globo terráqueo" width="428" height="328" >
                        </div>
                        <div class="relative flex flex-col lg:flex-row justify-between items-center">
                            <div class="text-center lg:text-left lg:max-w-xl">
                                <h3 class="h3 text-white mb-2 text-lg font-bold uppercase">Tienes alguna pregunta?</h3>
                                <p class="text-gray-300 text-lg mb-6">Escribenos al siguiente correo para recibir soporte o asistencia con cualquier inquietud o incidencia.</p>
                                <a href="mailto:ventanilla.dgcg@ip.gob.hn" class="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">ventanilla.dgcg@ip.gob.hn</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </main>
</template>
<script>
import {getCookie, parseTextToJsonArray} from '@/shared/utils'
import Api from "@/services/api";
import Footer from "@/components/Footer.vue";
import TopHeader from "@/components/TopHeader.vue";
import CatalogueIcon from "@/components/icons/CatalogueIcon.vue";
import CadastralIcon from "@/components/icons/CadastralIcon.vue";
import GeoporalIcon from "@/components/icons/GeoporalIcon.vue";
import GeodesicIcon from "@/components/icons/GeodesicIcon.vue";
import SchoolIcon from "@/components/icons/SchoolIcon.vue";
import FlyIcon from "@/components/icons/FlyIcon.vue";
import EmergencyIcon from "@/components/icons/EmergencyIcon.vue";
import WebUnderMaintenance from "@/components/WebUnderMaintenance.vue";
import logoIpGray from "@/assets/img/ip_logo_gray.png";
import logoGobGray from "@/assets/img/gob_logo_gray.png";
import earthImg from "@/assets/img/earth.png";


export default {
    components: {
        TopHeader,
        CatalogueIcon,
        CadastralIcon,
        GeoporalIcon,
        GeodesicIcon,
        SchoolIcon,
        FlyIcon,
        Footer,
        EmergencyIcon,
        WebUnderMaintenance
    },
    data() {
        return {
            services: [],
            logoIpGray,
            logoGobGray,
            earthImg
        };
    },
    mounted () {
        this.getProductsAndServicesAvailable()
    },
    methods: {
        async getProductsAndServicesAvailable () {
            const productsAndServices = getCookie('products_and_services');

            if (productsAndServices){
                const productsAndServicesJsonList = parseTextToJsonArray(productsAndServices);
                if (productsAndServicesJsonList == null){
                    return
                }
                if (productsAndServicesJsonList.map === undefined){
                    return
                }
                this.services = productsAndServicesJsonList
            }else{
                const {error, errorMessage, data} = await Api.getProducsAndServicesAvalaibles()
                if (error){
                    this.showErrorMessage(errorMessage)
                    return
                }
                this.services = data;
            }
        },
        getTailwindColor (color) {
            return {
                'text-gray-200 bg-gray-500' : 'gray' === color,
                'text-blue-200 bg-blue-500' : 'blue' === color,
                'text-indigo-200 bg-indigo-500' : 'indigo' === color,
                'text-green-200 bg-green-500' : 'green' === color,
                'text-teal-200 bg-teal-500' : 'teal' === color,
                'text-orange-200 bg-orange-500' : 'orange' === color,
                'text-red-200 bg-red-500' : 'red' === color,
                'text-emerald-200 bg-emerald-500' : 'emerald' === color,
                'text-yellow-200 bg-yellow-500' : 'yellow' === color,
            }
        },
        showErrorMessage(message) {
            this.$toast.open({
                message: message,
                type: 'error',
                position: 'top-right',
            });
        }
    }
};
</script>