<template>
    <button :title="title" :class="buttonClass" class="h-11 w-11  text-black/70 hover:bg-white/60  backdrop-blur flex items-center justify-center rounded-xl shadow pointer-events-auto">
        <slot />
    </button>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: "none"
        },
        isActive: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        buttonClass() {
            return {
                'bg-green-400/80': this.isActive,
                'bg-white/80': !this.isActive,
            }
        }
    }
};
</script>
