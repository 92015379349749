<template>
    <ul class=" w-full grid gap-2 grid-cols-1 md:grid-cols-2">
        <li v-for="(option, index) in options" :key="index">
            <input type="radio" :id="'option_' + index" :value="option" v-model="selectedFees" class="hidden peer" required >
            <label :for="'option_' + index" class="flex items-center h-full p-5 text-gray-700 bg-white border border-gray-200 rounded-lg cursor-pointer   peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 peer-disabled:opacity-60 peer-disabled:cursor-not-allowed w-full text-center">
                <div class="block w-full">
                    <span class="w-full text-base line-clamp-2" >{{option.period_title}}</span>
                    <h2 class="w-full text-2xl font-semibold ">L {{option.price}}</h2>
                    <small class="w-full text-sm line-clamp-2" >{{option.title}}</small>
                </div>
            </label>
        </li>
        <li class="col-start-2 flex flex-col text-center justify-center items-center" v-if="status === HttpRequestStatus.LOADING">
            <Spinner></Spinner>
            <p class="text-gray-400">Obteniendo tarifas...</p>
        </li>
        <li class="col-start-2 p-6 w-full flex flex-col items-center text-center text-gray-400" v-if="options.length===0 && status !== HttpRequestStatus.LOADING">
            <svg xmlns="http://www.w3.org/2000/svg" class="inline-flex" width="40" height="40" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M15 15m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                <path d="M18.5 18.5l2.5 2.5"></path>
                <path d="M4 6h16"></path>
                <path d="M4 12h4"></path>
                <path d="M4 18h4"></path>
            </svg>
            <span class="inline-flex max-w-xl">No hay tarifas disponibles</span>
        </li>
    </ul>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import {HttpRequestStatus} from "@/constants";

export default {
    computed: {
        HttpRequestStatus() {
            return HttpRequestStatus
        }
    },
    components: {Spinner},
    props: {
        options: {
            type: Array,
            required: true,
        },
        value: {
            type: String, // or whichever data type you expect
            required: true,
        },
        status: {
            type: String, // or whichever data type you expect
            required: true,
        },
    },
    data() {
        return {
            selectedFees: this.value,
        };
    },
    watch: {
        selectedFees(newVal) {
            this.$emit('update:modelValue', newVal)
        },
    },
};
</script>