<template>
     <div :class="[modalContainerClass]" class="py-4 transition-all fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <!-- Modal -->
        <div :class="modalClass"  class="transition-all delay-200 w-full bg-white rounded-t-lg sm:rounded-lg sm:m-4 sm:max-w-xl max-h-full overflow-auto" role="dialog">
            <header class="sticky top-0 py-4 bg-white/50 backdrop-blur px-6 w-full">
                <p class="text-lg font-semibold text-gray-700 text-center">
                    {{ title }}
                </p>
            </header>
            <main class="mt-2 mb-6 px-6">
                <slot/>
            </main>
            <footer  class="px-6 sticky bottom-0 flex flex-col items-center justify-end py-3 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 ">
                <FlatButton color="gray" width="fit" size="md"  @click="$emit('didCancelClick')">
                    {{  canceText }}
                </FlatButton>
                <FlatButton color="green" width="fit" size="md"  @click="$emit('didAcceptClick')">
                    {{ acceptText }}
                </FlatButton>
            </footer>
        </div>
    </div>
</template>
<script>
import FlatButton from "@/components/controls/FlatButton.vue";

export default {
    components: {FlatButton},
    props: {
        open: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        acceptText: {
            type: String,
            default: 'Aceptar'
        },
        canceText: {
            type: String,
            default: 'Cancelar'
        }
    },
    data() {
        return{
            overlayVisible: false
        }
    },
    watch : {
        open(val) {
            if (val){
                this.overlayVisible = true;
                return
            }
            setTimeout(() => {
                this.overlayVisible = val;
            }, 150)
        }
    },
    computed: {
        modalContainerClass() {
            return {
                'animate-hidemodaloverlay' : !this.open,
                'animate-showmodaloverlay' : this.open,
                'hidden' :!this.overlayVisible,
                'block' : this.overlayVisible
            };
        },
        modalClass() {
            return {
                'animate-hidemodal' : !this.open,
                'animate-showmodal' : this.open
            };
        }
    }
};
</script>
