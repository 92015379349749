<template>
    <div class="w-full flex items-center justify-center">
        <div v-if="serviceEnabled" class="h-fit w-full max-w-[1400px] bg-white shadow">
            <TopHeader title="FORMULARIO DE SOLICITUD" subtitle="DE MANTENIMIENTO CATASTRAL" />
            <hr class="border-gray-100 border mx-5">
            <div v-show="requestStatus === 'success'">
                <SuccessSentView
                    title="Gracias!"
                    description="Sus datos han sido recibidos correctamente"
                    @didResetFormRequest="resetFormRequest"
                />
            </div>
            <div v-show="requestStatus === 'error'">
                <FailedSentView
                    title="Error!"
                    :description=errorMessage
                    @didResetFormRequest="resetFormRequest"
                />
            </div>
            <main v-show="requestStatus === 'initial' || requestStatus === 'loading'" class="px-3 pb-4 pt-6">
                <form v-on:submit="sendRequest" action="#" method="post">
                    <section class="h-fit w-full">
                        <div class="w-full backdrop-blur bg-white/70 py-2 px-2 sticky top-0 z-20">
                            <h2 class="text-xl font-bold">Datos Generales</h2>
                        </div>
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-3 p-3">
                            <div class="col-span-1">
                                <label for="" class="p-0">Nombre Completo</label>
                                <InputField
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Nombres y Apellidos"
                                    icon="none"
                                    v-model="name"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Tipo de Identificación</label>
                                <SelectField
                                    width="full"
                                    size="md"
                                    :is-required="false"
                                    placeholder="Seleccione Tipo de Identificación"
                                    :options="typeIds"
                                    v-model="typeIdentitySelected"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Identificación</label>
                                <InputField
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Identidad"
                                    icon="none"
                                    v-model="identity"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Correo Electronico</label>
                                <InputField
                                    type="email"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Correo Electronico"
                                    icon="none"
                                    v-model="email"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Telefono</label>
                                <InputField
                                    type="number"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Telefono"
                                    icon="none"
                                    v-model="phone"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Celular</label>
                                <InputField
                                    type="number"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Celular"
                                    icon="none"
                                    v-model="mobile"
                                />
                            </div>

                        </div>
                    </section>

                    <section class="h-fit w-full">
                        <div class="w-full backdrop-blur bg-white/70 py-2 px-2 sticky top-0 z-20">
                            <h2 class="text-xl font-bold">Información de ubicación del Inmueble </h2>
                        </div>
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-3 p-3">
                            <div class="col-span-1">
                                <label for="" class="p-0">Departamento</label>
                                <SelectField
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Seleccione su departamento"
                                    :options="departments"
                                    @change="onSelectedDepartment"
                                    v-model="department"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Municipio</label>
                                <SelectField
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Seleccione el municipio"
                                    :options="municipalities"
                                    v-model="municipality"
                                />
                            </div>
                            <div class="col-span-1 md:col-span-2">
                                <label for="" class="p-0">Dirrección detallada del inmueble</label>
                                <TextInputField
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Dirreccion Detallada"
                                    icon="none"
                                    v-model="address"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Clave Catastral</label>
                                <InputField
                                    type="text"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Clave Catastral"
                                    icon="none"
                                    v-model="cadastralKey"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Tamaño estimado del predio en (Mz)</label>
                                <InputField
                                    type="number"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Tamaño estimado del predio"
                                    icon="none"
                                    min="0"
                                    step=".01"
                                    v-model="estimatedLandSize"
                                />
                            </div>
                            <div class="col-span-1 md:col-span-2">
                                <label for="" class="p-0">Verificación de seguridad</label>
                                <RecaptchaForm
                                    @didRecaptchaVerified="recaptchaVerified"
                                    @didRecaptchaError="recaptchaError"
                                    @didRecaptchaExpired="recaptchaExpired"
                                />
                            </div>
                        </div>
                        <hr class="border-2 border-gray-100" />
                    </section>

                    <div class="col-span-2 flex text-center justify-center items-center w-full" v-if="requestStatus === 'loading'">
                        <div class="flex flex-col justify-center items-center">
                            <span>Espera un momento estamos procesando tu solicitud</span>
                            <Spinner color="green" />
                        </div>
                    </div>
                    <div v-if="requestStatus !== 'loading'" class="col-span-1 md:col-span-2 flex justify-end items-center space-x-2 mb-10">
                        <div>
                            <FlatButton @click="this.$router.back()" width="fit" size="md" color="gray">Regresar</FlatButton>
                        </div>
                        <div>
                            <FlatButton width="fit" color="green" size="md">Enviar Solicitud</FlatButton>
                        </div>
                    </div>
                </form>
            </main>
            <Footer/>
        </div>

        <div v-if="!serviceEnabled" class="h-fit w-full max-w-[1400px] bg-white shadow">
            <ProductOrServiceDisabled />
        </div>
    </div>
</template>

<script>
import 'alpinejs'
import Api from '@/services/api'
import Footer from "@/components/Footer.vue";
import logo from "@/assets/img/logo.png";
import InputField from "@/components/controls/InputField.vue";
import SelectField from "@/components/controls/SelectField.vue";
import TextInputField from "@/components/controls/TextInputField.vue";
import Spinner from "@/components/Spinner.vue";
import FlatButton from "@/components/controls/FlatButton.vue";
import TopHeader from "@/components/TopHeader.vue";
import RecaptchaForm from "@/components/RecaptchaForm.vue";
import SuccessSentView from "@/components/SuccessSentView.vue";
import FailedSentView from "@/components/FailedSentView.vue";
import {ServiceAndProductCode, TYPE_IDS} from "@/constants";
import ProductOrServiceDisabled from "@/components/ProductOrServiceDisabled.vue";

export default {
    name: 'Geodesicos',
    components: {
        ProductOrServiceDisabled,
        TopHeader,
        FlatButton, Spinner,
        TextInputField,
        SelectField, InputField,
        Footer,
        RecaptchaForm,
        SuccessSentView,
        FailedSentView
    },
    data() {
        return {
            serviceEnabled: true,
            logo,
            recaptchaToken:null,
            errorMessage: '',
            name: "",
            typeIdentitySelected: '',
            identity: "",
            departmentSelected: {},
            municipalitySelected: {},
            address: "",
            cadastralKey: '',
            estimatedLandSize: 0,
            email: "",
            phone: "",
            mobile: "",
            requestStatus: 'initial',
            departments: [],
            municipalities: [],
            typeIds: TYPE_IDS,
        }
    },
    computed: {},
    mounted() {
        this.getProducsAndServiceAvalaibleByCode()
        this.getDepartmentsFromApi()
    },
    methods: {
        async getProducsAndServiceAvalaibleByCode () {
            const {data} = await Api.getProducsAndServiceAvalaibleByCode(ServiceAndProductCode.CADASTRAL_MAINTENANCE)
            const {active} = data
            if (active != null){
                this.serviceEnabled = active
            }
        },
        async getDepartmentsFromApi() {
            const {error, errorMessage, data} = await Api.getDepartmentsFromApi()
            if (error){
                 this.showErrorMessage(errorMessage)
                 return
            }
            this.departments = data;
        },
        async getMunicipalitiesFromApi(deptoCode) {
            const {error, data} = await Api.getMunicipalitiesFromApi(deptoCode)
            if (error){
                 return
            }
            this.municipalities = data;
        },
        onSelectedDepartment({target}) {
            const {selectedIndex} = target
            if (selectedIndex > 0){
                const selectedDepartment = this.departments[selectedIndex-1]
                this.getMunicipalitiesFromApi(selectedDepartment.code)
            }else{
                this.municipalities = []
            }
        },
        sendRequest: function (event) {
            event.preventDefault();

            var formdata = new FormData();
            formdata.append('name', this.name);
            formdata.append('identity_type', this.typeIdentitySelected.id);
            formdata.append('identity', this.identity);
            formdata.append('department', this.department.id);
            formdata.append('municipality', this.municipality.id);
            formdata.append('address', this.address);
            formdata.append('cadastral_key', this.cadastralKey);
            formdata.append('phone', this.phone);
            formdata.append('mobile', this.mobile);
            formdata.append('email', this.email);
            formdata.append('estimated_land_size', this.estimatedLandSize);
            formdata.append('recaptcha_token', this.recaptchaToken);

            if (this.recaptchaToken){
                this.sendCadastralMaintenanceRequest(formdata)
            }else{
                this.$toast.open({
                    message: 'Por favor complete la verificación de seguridad',
                    type: 'error'
                })
            }
        },
        async sendCadastralMaintenanceRequest(formdata) {
            this.requestStatus = "loading"
            const {error, errorMessage} = await Api.sendCadastralMaintenanceRequest(formdata)
            if (error){
                this.requestStatus = "error"
                if (errorMessage){
                   this.errorMessage = errorMessage
                }
                 return
            }

            this.requestStatus = "success"
            this.cleanForm()
        },
        cleanForm() {
            this.name = "";
            this.typeIdentitySelected = '';
            this.identity = "";
            this.email = "";
            this.phone = "";
            this.mobile = "";
            this.departmentSelected = {};
            this.municipalitySelected = {};
            this.address = "";
            this.cadastralKey = '';
            this.estimatedLandSize = 0;
            this.recaptchaToken = null;
        },
        recaptchaVerified(token) {
            this.recaptchaToken = token;
        },
        recaptchaError(reason) {
           this.$toast.open({
                message: 'Error al obtener recaptcha: ' + reason,
                type: 'error'
           })
        },
        recaptchaExpired(){
            this.recaptchaToken = null;
        },
        resetFormRequest(){
            this.requestStatus = 'initial';
        },
        showErrorMessage(message) {
            this.$toast.open({
                  message: message,
                  type: 'error',
                  position: 'top-right',
            })
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>