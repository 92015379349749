<template>
    <div class="w-full flex items-center justify-center" >
        <div v-if="serviceEnabled" class="h-fit w-full max-w-[1400px] bg-white shadow">
            <TopHeader title="Formulario de ventas" subtitle="Ingresa los datos necesarios para completar tu compra" />
            <hr class="border-gray-100 border mx-5">
            <div v-show="requestStatus === 'error'">
                <FailedSentView
                    title="Error!"
                    :description=errorMessage
                    @didResetFormRequest="resetFormRequest"
                />
            </div>
            <div v-show="requestStatus === 'success'">
                <SuccessSentView
                    title="Gracias!"
                    description="Sus datos han sido recibidos correctamente"
                    @didResetFormRequest="resetFormRequest"
                />
            </div>
            <main v-show="requestStatus === 'initial' || requestStatus === 'loading'" class="grid grid-cols-1 md:grid-cols-4 px-3 pb-4 pt-6">
                <aside class="col-span-1 h-full " v-if="cartItems.length>0">
                    <CartSidebar
                        :cart-name="cartName"
                        :cart-items="cartItems"
                        @didChangeCart="getShoppingCartItems"
                        :is-checkout-view="true"
                        :show-back-button="productsType==='layer' || productsType==='image'"
                    />
                </aside>
                <div :class="[mainClass]">
                    <form @submit="submitForm">
                        <section class="h-fit">
                            <div class="w-full backdrop-blur bg-white/70 py-2 px-2 sticky top-0 z-20">
                                <h2 class="text-xl font-bold">Datos Generales</h2>
                            </div>
                            <div class="grid grid-cols-1 md:grid-cols-2 gap-3 p-3">
                                <div class="col-span-1">
                                    <label for="" class="p-0">Nombres</label>
                                    <InputField
                                        width="full"
                                        size="md"
                                        :is-required="true"
                                        placeholder="Nombres"
                                        icon="none"
                                        v-model="firstName"
                                    />
                                </div>
                                <div class="col-span-1">
                                    <label for="" class="p-0">Apellidos</label>
                                    <InputField
                                        width="full"
                                        size="md"
                                        :is-required="true"
                                        placeholder="Apellidos"
                                        icon="none"
                                        v-model="lastName"
                                    />
                                </div>
                                <div class="col-span-1">
                                    <label for="" class="p-0">Tipo de Identificación</label>
                                    <SelectField
                                        width="full"
                                        size="md"
                                        :is-required="false"
                                        placeholder="Seleccione Tipo de Identificación"
                                        :options="typeIds"
                                    />
                                </div>
                                <div class="col-span-1">
                                    <label for="" class="p-0">Identificación</label>
                                    <InputField
                                        type="number"
                                        width="full"
                                        size="md"
                                        :is-required="true"
                                        placeholder="Identidad"
                                        icon="none"
                                        v-model="identificationNumber"
                                    />
                                </div>
                                <div class="col-span-1">
                                    <label for="" class="p-0">Telefono</label>
                                    <InputField
                                        type="number"
                                        width="full"
                                        size="md"
                                        :is-required="true"
                                        placeholder="Telefono"
                                        icon="none"
                                        v-model="phoneNumber"
                                    />
                                </div>
                                <div class="col-span-1">
                                    <label for="" class="p-0">Correo Electronico</label>
                                    <InputField
                                        type="email"
                                        width="full"
                                        size="md"
                                        :is-required="true"
                                        placeholder="Correo Electronico"
                                        icon="none"
                                        v-model="email"
                                    />
                                </div>
                            </div>
                        </section>
                        <section class="h-fit" >
                            <div  class="w-full backdrop-blur bg-white/70 py-2 px-2 sticky top-0 z-20">
                                <h2 class="text-xl font-bold">Datos de Entrega</h2>
                            </div>
                            <div class="grid grid-cols-1 md:grid-cols-2 gap-3 p-3 w-full">
                                <div class="col-span-1 ">
                                    <label for="" class="p-0">Departamento</label>
                                    <SelectField
                                        width="full"
                                        size="md"
                                        :is-required="true"
                                        placeholder="Seleccione su departamento"
                                        :options="departments"
                                        @change="onSelectedDepartment"
                                        v-model="department"
                                    />
                                </div>
                                <div class="col-span-1 ">
                                    <label for="" class="p-0">Municipio</label>
                                    <SelectField
                                        width="full"
                                        size="md"
                                        :is-required="true"
                                        placeholder="Seleccione el municipio"
                                        :options="municipalities"
                                        v-model="municipality"
                                    />
                                </div>
                                <div v-for="(product, index) in cartItems" :key="index" class="col-span-1 md:col-span-2 w-full border border-gray-200  rounded" v-show=" (product.sale_type === 'physical' && productsType==='image') || (product.sale_type === 'both' && productsType==='image')">
                                    <h2 class="uppercase bg-gray-100 p-2"><strong>{{product.title}}</strong></h2>
                                    <main class="p-2">
                                        <h3 class="mb-2 font-medium text-gray-900">En cual de las siguientes sucursales te gustaria recibir el producto?</h3>
                                        <BranchSelector  :options="product.inventories" v-model="product.delivery_branch" :parent-index="index"  :value="0"/>
                                        <label for="third-party-picker">
                                            ¿Deseas que una tercera persona recoja el producto en la sucursal en tu nombre?
                                        </label>
                                        <div class="w-full grid grid-cols-2 gap-3">
                                            <section class="flex items-center pl-4 border border-gray-200 rounded ">
                                                <input v-model="product.picker" :id="'picker-yes-'+index" type="radio" :value="true" :name="'picker-radio'+index" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer" required>
                                                <label :for="'picker-yes-'+index" class="w-full py-4 ml-2 text-sm font-medium text-gray-900 cursor-pointer">Si</label>
                                            </section>
                                            <section class="flex items-center pl-4 border border-gray-200 rounded ">
                                                <input v-model="product.picker" :id="'picker-no-'+index" type="radio" :value="false" :name="'picker-radio'+index" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer" required>
                                                <label :for="'picker-no-'+index" class="w-full py-4 ml-2 text-sm font-medium text-gray-900 cursor-pointer">No</label>
                                            </section>
                                        </div>
                                        <section class="mt-3 grid grid-cols-2 gap-3" v-if="product.picker">
                                            <div class="col-span-1 ">
                                                <label for="" class="p-0">Identificación de la persona que recogerá el producto.</label>
                                                 <InputField
                                                    type="number"
                                                    width="full"
                                                    size="md"
                                                    :is-required="true"
                                                    placeholder="Identificación persona que reclamara el producto"
                                                    icon="none"
                                                    v-model="product.picker_id"
                                                />
                                            </div>
                                            <div class="col-span-1 " >
                                                <label for="" class="p-0">Nombre de la persona que recogerá el producto.</label>
                                                 <InputField
                                                    type="text"
                                                    width="full"
                                                    size="md"
                                                    :is-required="true"
                                                    placeholder="Nombre persona que reclamara el producto"
                                                    icon="none"
                                                    v-model="product.picker_name"
                                                />
                                            </div>
                                        </section>
                                    </main>
                                </div>
                            </div>
                        </section>
                        <section class="h-fit">
                            <div class="w-full backdrop-blur bg-white/70 py-2 px-2 sticky top-0 z-20">
                                <h2 class="text-xl font-bold">Datos de Pago</h2>
                            </div>
                            <div class="grid grid-cols-1 md:grid-cols-2 gap-3 p-3">
                                <div class="col-span-1 md:col-span-2">
                                    <label for="" class="p-0">Titular de la tarjeta</label>
                                    <InputField
                                        width="full"
                                        size="md"
                                        :is-required="true"
                                        placeholder="Titular de la Tarjeta"
                                        autocomplete="cc-name"
                                        icon="none"
                                        v-model="payerName"
                                    />
                                </div>
                                <div class="col-span-1 md:col-span-2 ">
                                    <label for="" class="p-0">Direccion de facturación de la tarjeta</label>
                                    <InputField
                                        width="full"
                                        size="md"
                                        placeholder="Ciudad, Pais"
                                        icon="none"
                                        :is-required="true"
                                        v-model="payerAddress"
                                    />
                                </div>
                                <div class="col-span-1 md:col-span-2">
                                    <label for="" class="p-0">Número de tarjeta de crédito</label>
                                    <CardInputField
                                        cardformat="number"
                                        width="full"
                                        size="md"
                                        placeholder="#### #### #### ####"
                                        autocomplete="cc-number"
                                        v-model="payerCardNumber"
                                    />
                                </div>
                                <div class="col-span-1">
                                    <label for="" class="p-0">Fecha de expiración (mes/año)</label>
                                    <CardInputField
                                        cardformat="expiry"
                                        width="full"
                                        size="md"
                                        placeholder="mm/aa"
                                        autocomplete="cc-exp"
                                        v-model="payerExpiryDate"
                                    />
                                </div>
                                <div class="col-span-1">
                                    <label for="" class="p-0">CVV</label>
                                    <CardInputField
                                        cardformat="cvv"
                                        width="full"
                                        size="md"
                                        placeholder="1234"
                                        autocomplete="cc-csc"
                                        v-model="payerCvv"
                                    />
                                </div>
                                <div class="col-span-1 md:col-span-2">
                                    <label for="" class="p-0">Verificación de seguridad</label>
                                    <RecaptchaForm
                                        @didRecaptchaVerified="recaptchaVerified"
                                        @didRecaptchaError="recaptchaError"
                                        @didRecaptchaExpired="recaptchaExpired"
                                    />
                                </div>
                                <div class="col-span-1 md:col-span-2 w-full">
                                    <hr class="border-2 border-gray-100 w-full" />
                                </div>
                                <div class="col-span-1 md:col-span-2 flex justify-around items-center py-6">
                                    <div>
                                        <span class="font-bold text-lg">Tarjetas Aceptadas</span>
                                        <img :src="cardLogos" width="150" alt="">
                                    </div>
                                    <div>
                                        <img :src="facLogo" width="150" alt="">
                                    </div>
                                </div>
                                <div class="col-span-1 md:col-span-2 w-full">
                                    <hr class="border-2 border-gray-100 w-full" />
                                </div>
                                <div class="col-span-1 md:col-span-2 flex" >
                                    <input class="mr-2 scale-125" type="checkbox" v-model="termAndConditions" name="terms-and-conditions" id="terms-and-conditions" required>
                                    <label for="terms-and-conditions">
                                        He leido y Acepto los <a target="_blank" :href="`${baseUrl}/term_and_conditions/`" class="text-sky-600 underline">terminos y condiciones del sitio </a>
                                    </label>
                                </div>
                                <div class="col-span-1 md:col-span-2 w-full">
                                    <hr class="border-2 border-gray-100 w-full" />
                                </div>
                                <div class="col-span-2 flex text-center justify-center items-center w-full" v-if="requestStatus === 'loading'">
                                    <div class="flex flex-col justify-center items-center">
                                        <span>Espera un momento estamos procesando tu pago</span>
                                        <Spinner color="green" />
                                    </div>
                                </div>
                                <div v-if="requestStatus !== 'loading'" class="col-span-1 md:col-span-2 flex justify-end items-center space-x-2 mb-10">
                                    <div>
                                        <FlatButton @click="this.$router.back()" width="fit" size="md" color="gray">Regresar</FlatButton>
                                    </div>
                                    <div>
                                        <FlatButton width="fit" color="green" size="md">Completar la   Compra</FlatButton>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </form>
                </div>
            </main>
            <Footer />
        </div>

        <div v-if="!serviceEnabled" class="h-screen w-screen flex justify-between relative">
            <ProductOrServiceDisabled />
        </div>
    </div>
</template>
<script>
import logo from "@/assets/img/logo.png";
import CartSidebar from "@/components/CartSidebar.vue";
import {getCookie, getObjectFromLocalStorage, setObjectToLocalStorage} from "@/shared/utils";
import InputField from "@/components/controls/InputField.vue";
import SelectField from "@/components/controls/SelectField.vue";
import cardLogos from "@/assets/img/logo.card.brands.png";
import facLogo from "@/assets/img/logo.fac.png";
import FlatButton from "@/components/controls/FlatButton.vue";
import Api from "@/services/api";
import {baseUrl, ServiceAndProductCode, ShoppingCartName, TYPE_IDS} from '@/constants'
import CardInputField from "@/components/controls/CardInputField.vue";
import Footer from "@/components/Footer.vue";
import Spinner from "@/components/Spinner.vue";
import BranchSelector from "@/components/controls/BranchSelector.vue";
import TopHeader from "@/components/TopHeader.vue";
import RecaptchaForm from "@/components/RecaptchaForm.vue";
import FailedSentView from "@/components/FailedSentView.vue";
import SuccessSentView from "@/components/SuccessSentView.vue";
import ProductOrServiceDisabled from "@/components/ProductOrServiceDisabled.vue";

export default {
    name: 'CartoProductView',
    components: {
        ProductOrServiceDisabled,
        SuccessSentView,
        FailedSentView,
        RecaptchaForm,
        TopHeader,
        Spinner,
        Footer,
        CardInputField,
        FlatButton,
        SelectField,
        InputField,
        CartSidebar,
        BranchSelector,
    },
    data () {
        return {
            logo,
            cardLogos,
            facLogo,
            serviceEnabled: true,
            recaptchaToken:null,
            errorMessage: '',
            baseUrl,
            cartName:ShoppingCartName.CARTO_IMAGE,
            productsType: ServiceAndProductCode.CARTO_IMAGE,
            cartItems: [],
            departments: [],
            municipalities: [],
            typeIds: TYPE_IDS,
            firstName: '',
            lastName: '',
            identificationNumber: '',
            phoneNumber: '',
            email: '',
            department: '',
            municipality: '',
            payerName: '',
            payerAddress: '',
            payerCardNumber: '',
            payerExpiryDate: '',
            payerCvv: '',
            termAndConditions: false,
            requests: '',
            requestStatus: 'initial'
        }
    },
    computed: {
        mainClass(){
            return {
                "col-span-4 md:col-span-3": this.cartItems.length !== 0 ,
                "col-span-4 md:col-span-4": this.cartItems.length === 0,
            }
        },
    },
    mounted () {
        this.getProducsAndServiceAvalaibleByCode()
        this.setCurrentShopType()

        this.getShoppingCartItems()
        this.getDepartmentsFromApi()

    },
    methods: {
        async getProducsAndServiceAvalaibleByCode () {
            const {data} = await Api.getProducsAndServiceAvalaibleByCode(ServiceAndProductCode.SHOPPING_FORM)
            const {active} = data
            if (active != null){
                this.serviceEnabled = active
            }
        },
        setCurrentShopType () {
            const {cart_name} = this.$route.params;
            this.cartName = cart_name

            if (this.cartName === ShoppingCartName.CARTO_IMAGE) {
                this.productsType = ServiceAndProductCode.CARTO_IMAGE
            }else if (this.cartName === ShoppingCartName.LAYER) {
                this.productsType = ServiceAndProductCode.LAYER
            }else if (this.cartName === ShoppingCartName.CADASTRAL_MAINTENANCE){
                this.productsType = ServiceAndProductCode.CADASTRAL_MAINTENANCE

                const {quote_id} = this.$route.query;
                if (quote_id){
                    this.getCadastralMaintenanceQuoteFromApi(quote_id)
                }
            }else if (this.cartName === ShoppingCartName.GEODETIC){
                this.productsType = ServiceAndProductCode.GEODETIC

                const {quote_id} = this.$route.query;
                if (quote_id){
                    this.getGeodeticPointQuoteFromApi(quote_id)
                }
            }else if (this.cartName === ShoppingCartName.AERIAL_PHOTO){
                this.productsType = ServiceAndProductCode.AERIAL_PHOTO

                const {request_id} = this.$route.query;
                if (request_id){
                    this.getAerialPhotographyRequestFromApi(request_id)
                }
            }else if (this.cartName === ShoppingCartName.CENTER_POINT){
                this.productsType = ServiceAndProductCode.CENTER_POINT

                const {request_id} = this.$route.query;
                if (request_id){
                    this.getEducationCenterPointRequestFromApi(request_id)
                }
            }
        },
        addItemToShoppingCart (item) {
            let cartItems = [];
            const { id, title, files, sale_type, price, quantity, sheets} = item;

            cartItems.push({ id, title, files, sale_type, price, quantity, sheets, inventories:[], delivery_branch: 0, picker: false, picker_id:'', picker_name:''});

            setObjectToLocalStorage(this.cartName, cartItems)

            this.$toast.open({
                message: 'El producto se agregó al carrito correctamente',
                type: 'success',
                position: 'top-right',
            })
            this.getShoppingCartItems()
        },
        getShoppingCartItems () {
            if (getObjectFromLocalStorage(this.cartName) != null) {
                this.cartItems = getObjectFromLocalStorage(this.cartName)
            }
        },
        onSelectedDepartment({target}) {
            const {selectedIndex} = target
            if (selectedIndex > 0){
                const selectedDepartment = this.departments[selectedIndex-1]
                this.getMunicipalitiesFromApi(selectedDepartment.code)
                this.getInventoryByBranches(selectedDepartment.id)
            }else{
                this.municipalities = []
            }
        },
        async getCadastralMaintenanceQuoteFromApi(quote_id) {
            const {error, errorMessage, data} = await Api.getCadastralMaintenanceQuoteFromApi(quote_id);
            if (error){
                this.showErrorMessage(errorMessage)
                return
            }

            const { cadastral_maintenance, estimated_cost } = data;

            if (estimated_cost && cadastral_maintenance){
                await this.handleApiResponse(cadastral_maintenance, 'Cotización de Mantenimiento Catastral', estimated_cost);
            }
        },
        async getGeodeticPointQuoteFromApi(quote_id) {
            const {error, errorMessage, data} = await Api.getGeodeticPointQuoteFromApi(quote_id);
            if (error){
                this.showErrorMessage(errorMessage)
                return
            }

            const { geodetic_point, estimated_cost } = data;

            if (estimated_cost && geodetic_point) {
                await this.handleApiResponse(geodetic_point, 'Cotizacion de Punto Geodésico', estimated_cost);
            }
        },
        async getAerialPhotographyRequestFromApi(request_id) {
            const {error, errorMessage, data} = await Api.getAerialPhotographyRequest(request_id);
            if (error){
                this.showErrorMessage(errorMessage)
                return
            }

            const { service_info } = data;
            if (service_info) {
                await this.handleApiResponse(data, service_info.title, service_info.price);
            }
        },
        async getEducationCenterPointRequestFromApi(request_id) {
            const {error, errorMessage, data} = await Api.getEducationCenterPointRequest(request_id);
            if (error){
                this.showErrorMessage(errorMessage)
                return
            }

            const { service_info } = data;
            if (service_info){
                await this.handleApiResponse(data, service_info.title, service_info.price);
            }
        },
        async handleApiResponse(response, title, price) {
            if (response.error) {
                this.$toast.open({
                    message: response.error,
                    type: 'error',
                });
                setObjectToLocalStorage(this.cartName, []);
                this.getShoppingCartItems();
            } else {
                const { id, name, identity, phone, email, municipality, department} = response;
                const [firstName, lastName] = name.split(" ");

                this.payerName = name;
                this.firstName = firstName;
                this.lastName = lastName;
                this.identificationNumber = identity;
                this.phoneNumber = phone;
                this.email = email;
                this.municipality = municipality;
                this.department = department;

                const item = {
                    id,
                    title,
                    files: [{
                        file_format: "PDF",
                        price,
                    }],
                    sale_type: 'digital',
                    price,
                    quantity: 1,
                    sheets: [],
                    inventories: [],
                    delivery_branch: 0,
                };

                this.addItemToShoppingCart(item);
            }
        },
        async getDepartmentsFromApi() {
            const {error, errorMessage, data} = await Api.getDepartmentsFromApi()
            if (error){
                 this.showErrorMessage(errorMessage)
                 return
            }
            this.departments = data;
        },
        async getMunicipalitiesFromApi(deptoCode) {
            const {error, data} = await Api.getMunicipalitiesFromApi(deptoCode)
            if (error){
                 return
            }
            this.municipalities = data;
        },
        async getInventoryByBranches (id) {
            const {cartItems, productsType} = this
            cartItems.map(async (product) => {
                if (product.sale_type === "physical" || product.sale_type === "both") {
                    const {error, data} = await Api.getBranchesFromApi(id, product.id, productsType)
                    if (error){
                         return
                    }
                    product.inventories = data;
                }else{
                    product.inventories = []
                }
            })

            this.cartItems = cartItems
        },
        submitForm(evt) {
            evt.preventDefault()

            const formdata = new FormData();
            formdata.append('first_name', this.firstName);
            formdata.append('last_name', this.lastName);
            formdata.append('identity', this.identificationNumber);
            formdata.append('phone', this.phoneNumber);
            formdata.append('email', this.email);
            formdata.append('state', this.department.title);
            formdata.append('city', this.municipality.title);
            formdata.append('department_id', this.department.id);
            formdata.append('municipality_id', this.municipality.id);
            formdata.append('product_type', this.productsType);

            formdata.append('card_name', this.payerName);
            formdata.append('address_line1', this.payerAddress);
            const payerCardNumberWithoutSpaces = this.payerCardNumber.replace(/ /g,'');
            formdata.append('card_number', payerCardNumberWithoutSpaces);
            const [payerExpiryMonth, payerExpiryYear] = this.payerExpiryDate.split(" / ");
            const formattedExpiryYear = payerExpiryYear.length === 4 ? payerExpiryYear.substring(2) : payerExpiryYear;
            formdata.append('card_exp', `${formattedExpiryYear}${payerExpiryMonth}`);
            formdata.append('card_cvv', this.payerCvv);

            const sessionId = getCookie('session_id');
            if(sessionId){
                formdata.append('session_id', sessionId);
            }

            const soldProductList = this.cartItems.map(product => ({
                id: product.id,
                quantity: product.quantity,
                delivery_branch: product.delivery_branch,
                sale_type: product.sale_type,
                fee_id: product.fee_id,
                picker_id: product.picker_id,
                picker_name: product.picker_name
            }));

            const branchesHaveBeenSelected = soldProductList.every(item => {
                if ((item.sale_type === 'both' || item.sale_type === 'physical') && item.delivery_branch === 0) {
                    return false; // Invalid
                }
                return true; // Valid
            });

            if (!branchesHaveBeenSelected){
                this.$toast.open({
                    message: 'Por favor seleccione una sucursal de entrega válida para cada producto',
                    type: 'error',
                });
                return;
            }

            if (soldProductList.length > 0) {
                formdata.append('sold_product_list', JSON.stringify(soldProductList));
            }

            if (!this.termAndConditions){
                this.$toast.open({
                    message: 'Acepta la terminos y condiciones para continuar con la compra',
                    type: 'error',
                })
                return
            }

            if (this.cartItems.length === 0){
                this.$toast.open({
                    message: 'Agrega un elemento al carrito para poder realizar la compra',
                    type: 'error',
                })
                return
            }


            if (this.recaptchaToken){
                this.sendSaleToApi(formdata);
            }else{
                this.$toast.open({
                    message: 'Por favor complete la verificación de seguridad',
                    type: 'error'
                })
            }
        },
        async sendSaleToApi (formdata) {
            this.requestStatus = "loading";
            const {error, errorMessage, data} = await Api.sendSaleToApi(formdata);
            if (error){
                this.showErrorMessage(errorMessage)
                this.requestStatus = "error";
                return
            }

            // Transaction fallida
            if (data === undefined) {
                this.showErrorMessage('Se produjo un error al procesar la transacción de pago');
                this.requestStatus = "error";
                return;
            }

            // Redirect to FAC
            const { data: dataInResponseFac } = data;
            const { RedirectData } = dataInResponseFac;
            if (RedirectData){
                // Transaction exitosa
                this.requestStatus = "success";
                this.cartItems = [];
                setObjectToLocalStorage(this.cartName, []);

                window.open('url', '_self').document.write(RedirectData);
            }else{
                this.requestStatus = "error";
                this.showErrorMessage("Se produjo un error al redireccionar hacia la pasarela de pago");
            }

        },
        recaptchaVerified(token) {
            this.recaptchaToken = token;
        },
        recaptchaError(reason) {
            this.$toast.open({
                message: 'Error al obtener recaptcha: ' + reason,
                type: 'error'
            })
        },
        recaptchaExpired(){
            this.recaptchaToken = null;
        },
        resetFormRequest(){
            this.requestStatus = 'initial';
        },
        showErrorMessage(message) {
            this.$toast.open({
                message: message,
                type: 'error',
                position: 'top-right',
            })
        }
    },

}
</script>