//import 'ol/ol.css';
import Draw from 'ol/interaction/Draw';
import Overlay from 'ol/Overlay';
import {
	Circle as CircleStyle,
	Fill,
	Stroke,
	Style
} from 'ol/style';
import {
	LineString,
	Polygon
} from 'ol/geom';
import {
	getArea,
	getLength
} from 'ol/sphere';
import {
	unByKey
} from 'ol/Observable';
import {
	GEO_PROJ
} from '../constants'

/**
 * Currently drawn feature.
 * @type {import("../src/ol/Feature.js").default}
 */
let sketch;

/**
 * The help tooltip element.
 * @type {HTMLElement}
 */
let helpTooltipElement;

/**
 * Overlay to show the help messages.
 * @type {Overlay}
 */
let helpTooltip;

/**
 * The measure tooltip element.
 * @type {HTMLElement}
 */
let measureTooltipElement;

/**
 * Overlay to show the measurement.
 * @type {Overlay}
 */
let measureTooltip;

let draw; // global so we can remove it later

/**
 * Format length output.
 * @param {LineString} line The line.
 * @return {string} The formatted length.
 */
const formatLength = function (line) {
	const length = getLength(line, {
		projection: GEO_PROJ
	})
	let output;
	if (length > 100) {
		output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
	} else {
		output = Math.round(length * 100) / 100 + ' ' + 'm';
	}
	return output;
};

/**
 * Format area output.
 * @param {Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
const formatArea = function (polygon) {
	const area = getArea(polygon, {
		projection: GEO_PROJ
	});
	let output;
	if (area > 10000) {
		output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup>';
	} else {
		output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
	}
	return output;
};

export function removeInteraction(map) {
	map.removeInteraction(draw)
}

export function addInteraction(map, type) {
	map.removeInteraction(draw)
	var layers = map
		.getLayers()
		.getArray()
	
		var layer =	layers.filter((layer) => {
			return layer.getProperties().title == 'drawLayer'
		})[0]
	
	if (layer){
		const source = layer.getSource();
		
		draw = new Draw({
			source: source,
			type: type,
			style: new Style({
				fill: new Fill({
					color: 'rgba(255, 255, 255, 0.2)',
				}),
				stroke: new Stroke({
					color: 'rgba(0, 0, 0, 0.5)',
					lineDash: [10, 10],
					width: 2,
				}),
				image: new CircleStyle({
					radius: 5,
					stroke: new Stroke({
						color: 'rgba(0, 0, 0, 0.7)',
					}),
					fill: new Fill({
						color: 'rgba(255, 255, 255, 0.2)',
					}),
				}),
			}),
		});
		map.addInteraction(draw);
	
		createMeasureTooltip(map);
		createHelpTooltip(map);
	
		let listener;
		draw.on('drawstart', function (evt) {
			// set sketch
			sketch = evt.feature;
	
			/** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
			let tooltipCoord = evt.coordinate;
	
			listener = sketch.getGeometry().on('change', function (evt) {
				const geom = evt.target;
				let output;
				if (geom instanceof Polygon) {
					output = formatArea(geom);
					tooltipCoord = geom.getInteriorPoint().getCoordinates();
				} else if (geom instanceof LineString) {
					output = formatLength(geom);
					tooltipCoord = geom.getLastCoordinate();
				}
				measureTooltipElement.innerHTML = output;
				measureTooltip.setPosition(tooltipCoord);
			});
		});
	
		draw.on('drawend', function () {
			measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
			measureTooltip.setOffset([0, -7]);
			// unset sketch
			sketch = null;
			// unset tooltip so that a new one can be created
			measureTooltipElement = null;
			createMeasureTooltip(map);
			unByKey(listener);
		});
	}
}

/**
 * Creates a new help tooltip
 */
function createHelpTooltip(map) {
	if (helpTooltipElement) {
		helpTooltipElement.parentNode.removeChild(helpTooltipElement);
	}
	helpTooltipElement = document.createElement('div');
	helpTooltipElement.className = 'ol-tooltip hidden';
	helpTooltip = new Overlay({
		element: helpTooltipElement,
		offset: [15, 0],
		positioning: 'center-left',
	});
	map.addOverlay(helpTooltip);
}

/**
 * Creates a new measure tooltip
 */
function createMeasureTooltip(map) {
	if (measureTooltipElement) {
		measureTooltipElement.parentNode.removeChild(measureTooltipElement);
	}
	measureTooltipElement = document.createElement('div');
	measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
	measureTooltip = new Overlay({
		element: measureTooltipElement,
		offset: [0, -15],
		positioning: 'bottom-center',
		stopEvent: false,
		insertFirst: false,
	});
	map.addOverlay(measureTooltip);
}