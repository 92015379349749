<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="23.75" height="23.7598">
 <g>
  <rect height="23.7598" opacity="0" width="23.75" x="0" y="0"/>
  <path d="M11.875 21.123C16.9531 21.123 21.0938 16.9727 21.0938 11.8848C21.0938 6.79688 16.9531 2.64648 11.875 2.64648C6.79688 2.64648 2.65625 6.79688 2.65625 11.8848C2.65625 16.9727 6.79688 21.123 11.875 21.123ZM11.875 19.5312C7.63672 19.5312 4.29688 16.1621 4.29688 11.8848C4.29688 7.60742 7.63672 4.23828 11.875 4.23828C16.1133 4.23828 19.4531 7.60742 19.4531 11.8848C19.4531 16.1621 16.1133 19.5312 11.875 19.5312ZM11.875 23.7598C12.2656 23.7598 12.5684 23.4473 12.5684 23.0566L12.5684 16.4453C12.5684 16.0547 12.2656 15.752 11.875 15.752C11.4844 15.752 11.1719 16.0547 11.1719 16.4453L11.1719 23.0566C11.1719 23.4473 11.4844 23.7598 11.875 23.7598ZM0.703125 12.5879L7.31445 12.5879C7.70508 12.5879 8.01758 12.2754 8.01758 11.8848C8.01758 11.4941 7.70508 11.1914 7.31445 11.1914L0.703125 11.1914C0.3125 11.1914 0 11.4941 0 11.8848C0 12.2754 0.3125 12.5879 0.703125 12.5879ZM11.875 8.02734C12.2656 8.02734 12.5684 7.71484 12.5684 7.32422L12.5684 0.712891C12.5684 0.322266 12.2656 0.00976562 11.875 0.00976562C11.4844 0.00976562 11.1719 0.322266 11.1719 0.712891L11.1719 7.32422C11.1719 7.71484 11.4844 8.02734 11.875 8.02734ZM16.4355 12.5879L23.0469 12.5879C23.4375 12.5879 23.75 12.2754 23.75 11.8848C23.75 11.4941 23.4375 11.1914 23.0469 11.1914L16.4355 11.1914C16.0449 11.1914 15.7324 11.4941 15.7324 11.8848C15.7324 12.2754 16.0449 12.5879 16.4355 12.5879Z" fill="currentColor"/>
 </g>
</svg>

</template>
<script>
export default {
    props: {
    }
};
</script>
