<template>
    <section class="bg-white w-full">
        <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <TopHeader title="" />
            <div class="mx-auto max-w-screen-sm text-center">
                <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600">: (</h1>
                <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">Producto o Servicio Deshabilitado.</p>
                <p class="mb-4 text-lg font-light text-gray-500">El servicio al que deseas acceder está actualmente deshabilitado. Te invitamos a intentarlo en otro momento.</p>
                <a href="/inicio" class="inline-flex text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">Regresar al Inicio</a>
            </div>
        </div>
    </section>
</template>
<script>
import TopHeader from "@/components/TopHeader.vue";

export default {
    components: {TopHeader}
};
</script>
