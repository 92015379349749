<template>
    <div class="geolayer-box max-h-[500px] min-h-fit overflow-auto bg-white shadow rounded-xl pointer-events-auto">
        <div class="w-full" :class="baseLayersClass">
            <header class="z-20 bg-gray-200/90 sticky top-0">
                <button class="px-3 py-2 flex justify-between items-center w-full" @click="didCollapseBaseLayers">
                    <strong>Capas Base</strong>
                    <i :class="baseLayersIconClass"></i>
                </button>
            </header>
            <main class="flex justify-between px-3 bg-white/40 py-2">
                <button class="rounded-xl overflow-hidden border-2 border-transparent hover:scale-110 transition-transform" v-for="layer in baseLayers"
                        :key="layer.name" :class="{ 'border-sky-400': layer.visible }"
                        @click="showBaseLayer(layer.name)">
                    <img v-if="layer.thumbnail === 'basic.thumbnail.png'" src="@/assets/img/basic.thumbnail.png" width="73" alt="">
                    <img v-if="layer.thumbnail === 'satelite.thumbnail.png'" src="@/assets/img/satelite.thumbnail.png" width="73" alt="">
                    <img v-if="layer.thumbnail === 'terrain.thumbnail.png'" src="@/assets/img/terrain.thumbnail.png" width="73" alt="">
                </button>
            </main>
        </div>
        <div class="w-full" :class="layersClass">
            <header class="z-20 bg-gray-200/90 sticky top-0">
                <button class="px-3 py-2 flex justify-between items-center w-full" @click="didCollapseLayers">
                    <strong>Capas</strong>
                    <i :class="layersIconClass"></i>
                </button>
            </header>
            <main class="flex justify-between bg-white/40 w-full">
                <ul class="w-full">
                    <li v-for="(category, index) in categories" :key="category.id" :class="categoryClass(category.id)" class=" overflow-hidden w-full" >
                        <button @click="toggleCategory(category.id)"  class="w-full h-10 text-left font-medium text-md px-3 overflow-hidden truncate" :style="'background: rgba(140, 140, 140, 0.'+index+')'">
                            <i class="mr-2" :class="categoryIconClass(category.id)"></i>
                            <span>{{category.title}}</span>
                        </button>
                        <ul >
                            <li class="flex justify-between items-center space-x-1 text-sm px-3 border-t border-t-gray-400/10 hover:bg-gray-100" v-for="layer in layers" :key="layer.id" v-show="category.id===layer.category">
                                <label class="flex items-center space-x-1 w-full cursor-pointer max-w-[250px]">
                                    <input class="scale-110 cursor-pointer" :name="layer.id" :id="layer.id" @change="$emit('didCheckedLayer', $event ,layer)" type="checkbox" :checked="layer.visible" >
                                    <span>{{layer.title}}</span>
                                </label>
                                <div class="flex items-center space-x-1">
                                    <a class="px-2 hover:text-sky-600 transition-colors" :href="layer.metadata_link" target="_blank" title="Mostrar metadatos de la capa">
                                        <i class="fa fa-info"></i>
                                    </a>
                                    <button class="px-2 hover:text-sky-600 transition-colors" v-show="layer.is_salable" @click="$emit('didClickAddToCart', layer)" title="Agregar al carrito de compras">
                                        <i class="fa fa-cart-plus"></i>
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </main>
        </div>
    </div>
</template>

<script>
import Api from '@/services/api'

export default {
    name: 'GeoLayerBox',
    props: ['baseLayers', 'categories', 'layers', 'map'],
    data () {
        return {
            categoryExpanded: -1,
            baseLayerCollapsed: false,
            layerCollapsed: false,
        }
    },
    methods: {
        showBaseLayer (name) {
            const layers = this.map.map.getLayers().getArray();

            for (const baseLayer of layers) {
                if (['basic', 'satelite', 'terrain'].includes(baseLayer.values_.title)) {
                    baseLayer.setVisible(false);
                }

                if (baseLayer.values_.title === name) {
                    baseLayer.setVisible(true);
                }
            }

            let layer = this.baseLayers.find(layer => layer.visible);
            if (layer) {
                layer.visible = false;
            }

            layer = this.baseLayers.find(layer => layer.name === name);
            if (layer) {
                layer.visible = true;
            }
        },

        showConfirmAddToLayerModal () {

        },
        async showModalMetadatos (layer) {
            var data = await Api.getMetadatosFromApi(layer.code)
            this.layerMetadata = data

            if (data.metadata) {
                this.modalMetadatosIsHidden = false
            }
        },
        categoryClass (id) {
            return {
                'h-fit' : this.categoryExpanded === id,
                'h-10' : this.categoryExpanded !== id,
            }
        },
        categoryIconClass (id){
            return {
                'fa fa-folder-open' : this.categoryExpanded === id,
                'fa fa-folder' : this.categoryExpanded !== id,
            }
        },
        toggleCategory (id) {
            if (this.categoryExpanded === id) {
                this.categoryExpanded = -1
            } else {
                this.categoryExpanded = id
            }
        },
        didCollapseBaseLayers(){
            this.baseLayerCollapsed = !this.baseLayerCollapsed
        },
        didCollapseLayers(){
           this.layerCollapsed = !this.layerCollapsed
        }
    },
    computed:{
        baseLayersClass() {
            return {
                'h-10 overflow-hidden': this.baseLayerCollapsed,
            }
        },
        baseLayersIconClass(){
            return this.baseLayerCollapsed ? 'fa fa-chevron-down': 'fa fa-chevron-up'
        },
        layersClass() {
            return {
                'h-10 overflow-hidden': this.layerCollapsed,
            }
        },
        layersIconClass(){
            return this.layerCollapsed ? 'fa fa-chevron-down': 'fa fa-chevron-up'
        }
    }
}
</script>
