<template>
    <article class="py-2 border-b relative" :class="itemClass">
        <h4 class="text-md font-semibold">{{ product.title }}</h4>
        <small class="text-sm" v-if="product.subtitle">{{ product.subtitle }}</small>
        <div v-if="product.sale_type !== 'digital'" class="flex justify-between items-center">
            <label class="font-bold text-sm" for="quantity">Cantidad</label>
            <div class="w-16">
                <InputField
                    type="number"
                    width="full"
                    size="sm"
                    placeholder="Qty"
                    :model-value="product.quantity"
                    @input="updateQty"
                />
            </div>
        </div>
        <div class="grid grid-cols-2 font-bold text-sm">
            <section class="col-span-1">
                Formato
            </section>
            <section class="col-span-1">
                Precio
            </section>
        </div>
        <div class="grid grid-cols-2 text-sm" v-if="product.sale_type !== 'digital'">
            <section class="col-span-1">
                Fisico
            </section>
            <section class="col-span-1">
                L {{ product.price }} * {{product.quantity}}
            </section>
        </div>
        <div class="w-full text-sm" v-for="(file, file_key) in product.files" :key="file_key">
            <div class="w-full grid grid-cols-2" v-if="product.sale_type !== 'physical'">
                <section class="col-span-1" >
                    {{file.file_format}}
                </section>
                <section class="col-span-1">
                    L {{ file.price }}
                </section>
            </div>
        </div>
        <div v-for="(sheet, key) in product.sheets" :key="key">
            <div class="grid grid-cols-2 text-sm" v-if="sheet.sale_type !== 'digital'">
                <section class="col-span-1">
                    Fisico
                </section>
                <section class="col-span-1">
                    L {{ sheet.price }} * {{sheet.quantity}}
                </section>
            </div>
            <div class="w-full text-sm" v-for="(file, file_key) in sheet.attach_files" :key="file_key">
                <div class="w-full grid grid-cols-2" v-if="sheet.sale_type !== 'physical'">
                    <section class="col-span-1" >
                        {{file.file_format}}
                    </section>
                    <section class="col-span-1">
                        L {{ file.price }}
                    </section>
                </div>
            </div>
        </div>
        <button
            v-if="showDeleteButtons"
            @click="$emit('onDelete')"
            class="
                md:relative w-full
                lg:absolute mb-2 right-0
                lg:w-8 rounded top-2 bottom-2
                bg-red-100 text-red-500
                border-2 border-transparent
                hover:bg-red-100/80
                hover:border-red-500/30
                active:scale-95
                transition-all
                flex
                justify-center
                items-center
                mt-3
                py-1 md:py-2
            "
        >
            <i class="fa fa-trash"></i>
            <span class="block md:hidden ml-2"> Eliminar</span>
        </button>
    </article>
</template>
<script>

import InputField from "@/components/controls/InputField.vue";

export default {
    components: {InputField},
    props: {
        product: {
            title: String,
            subtitle: String,
            sale_type: String,
            quantity:Number,
            id: Number,
            price: String,
            files: {
              type: [Array,Object],
              require: true
            },
            sheets: {
              type: [Array,Object],
              require: false
            },
        },
        showDeleteButtons: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        updateQty({target}){
            const product = { ...this.product };
            product.quantity = target.value
            this.$emit('updateQuantity', product);
        }
    },
    data() {

    },
    computed: {
        itemClass() {
            return {
                'pr-0 lg:pr-10': this.showDeleteButtons === true,
                'pr-0 lg:pr-0': this.showDeleteButtons === false
            }
        }
    },
};
</script>
