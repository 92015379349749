<template>
    <section class="sticky top-0 p-2 max-h-screen flex flex-col justify-between">
        <div class="z-10 border border-gray-200/70 px-4 rounded-lg bg-white overflow-auto max-h-fit">
            <div class="flex justify-between border-b-2 pt-4 sticky top-0 bg-white z-10">
                <h2 class="text-xl font-bold">Tu Carrito</h2>
                <span >
                    <i class="fa fa-shopping-cart"></i>
                    <b class="ml-2">{{cartItems.length}}</b>
                </span>
            </div>
            <CartItem
                v-for="(product, key) in cartItems"
                :key="key"
                :product="product"
                @updateQuantity="updateQuantity"
                @onDelete="deleteShoppingCartItem(key)"
                :show-delete-buttons="!isCheckoutView"
            />
            <div v-if="cartItems.length===0" class="h-32 w-full text-gray-500 text-center">
                <div class="flex flex-col border-b-2 border-b-gray-100 w-full h-full p-3 items-center justify-center ">
                    <i class="fa fa-list"></i>
                    <small>Aún no has agregado ningún producto a tu carrito de compras</small>
                </div>
            </div>
            <div class="flex justify-between pt-2 text-md sticky bottom-0 bg-white z-10 pb-3">
                <h2 class="font-bold">Total</h2>
                <span >
                    L {{ calculateTotal }}
                </span>
            </div>
        </div>
        <div class="w-full pt-2" v-show="showBackButton===true">
            <FlatButton v-if="!isCheckoutView && cartItems.length!==0" color="green" size="md" width="full" @click="$emit('onClickCompleteShopping')">
                Proceder a pagar
            </FlatButton>
            <FlatButton v-if="isCheckoutView" @click="this.$router.back()" width="full" size="md" color="sky">
                <i class="fas fa-chevron-left"></i> Agregar mas productos
            </FlatButton>
        </div>
    </section>
</template>
<script>
import FlatButton from "@/components/controls/FlatButton.vue";
import CartItem from "@/components/items/CartItem.vue";
import {setObjectToLocalStorage} from "@/shared/utils";

export default {
    components: {CartItem, FlatButton},
    props: {
        cartName: {
            type: String,
            required: true
        },
        cartItems: {
            type: [Array,Object],
            required: true
        },
        isCheckoutView: {
            type: Boolean,
            default: false,
            required: true
        },
        showBackButton: {
            type: Boolean,
            default: true,
            required: true
        }
    },
    methods:{
        deleteShoppingCartItem (key) {
            const items = this.cartItems;
            items.splice(key, 1)
            setObjectToLocalStorage(this.cartName, this.cartItems)

            this.$toast.open({
                message: 'El producto ha sido eliminado correctamente',
                type: 'success',
                position: 'top-right',
            })

            this.$emit('didChangeCart');
        },
        updateQuantity(product){
            const items = this.cartItems;
            const modifiedArray = items.map((obj) => {
              if (obj.id === product.id) {
                return { ...obj, quantity: product.quantity };
              }
              return obj;
            });
            setObjectToLocalStorage(this.cartName, modifiedArray)
            this.$emit('didChangeCart', product);
        },
        calculatePriceSheets(item) {
            if (item.sheets === undefined || item.sheets === null) {
                return 0
            }

            return item.sheets.reduce((sumSheetPrice, sheet) => {
                const priceSheetFiles = sheet.attach_files.reduce((sumFilePrice, file) => sumFilePrice + parseFloat(file.price), 0)
                const physicalSheetPrice = sheet.sale_type !== 'digital' ? parseFloat(sheet.price * sheet.quantity) : 0
                if (sheet.sale_type === 'both') {
                    return sumSheetPrice + priceSheetFiles + physicalSheetPrice
                } else if (sheet.sale_type === 'digital') {
                    return sumSheetPrice + priceSheetFiles
                } else if (sheet.sale_type === 'physical') {
                    return sumSheetPrice + physicalSheetPrice
                }
            }, 0)
        }
    },
    computed:{
        calculateTotal () {
              let total = 0
              total = this.cartItems.reduce((sumTotal, item) => {
                  const priceSheets = this.calculatePriceSheets(item)
                  const {files} = item

                  if (files){
                      const priceFiles = item.files.reduce((sumFilePrice, file) => sumFilePrice + parseFloat(file.price),0)
                      const physicalPrice = item.sale_type !== 'digital' ? parseFloat(item.price * item.quantity) : 0
                      if (item.sale_type === 'both') {
                        return sumTotal + priceFiles + physicalPrice + priceSheets
                      }else if (item.sale_type === 'digital') {
                          return sumTotal + priceFiles + priceSheets
                      }else if (item.sale_type === 'physical') {
                          return sumTotal + physicalPrice + priceSheets
                      }
                  }
              }, 0);
              if (total){
                total = parseFloat(total.toFixed(2)).toFixed(2);
              }
              return total;
        },
    }
};
</script>
