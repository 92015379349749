<template>
    <main class="bg-white">
        <div v-if="serviceEnabled" class="w-full flex items-center justify-center">
            <div class="h-fit w-full">
                <header class="w-full max-w-[1400px] mx-auto flex flex-col pt-5 px-5 ">
                    <section class="flex items-center justify-between">
                       <img :src="logo" alt="Logo" width="200">
                        <div class="relative cart-button">
                            <small class="flex justify-center items-center rounded-full absolute -top-2 -right-2 bg-red-600 text-white w-4 h-4 shadow z-40" v-if="cartItems.length>0">{{cartItems.length}}</small>
                            <FlatButton width="fit" size="md" color="green" @click="hideShoppingCart = !hideShoppingCart">
                                <i class="fa fa-shopping-cart"></i>
                            </FlatButton>
                        </div>
                    </section>
                    <section class="py-4 ">
                        <h1 class="font-black text-3xl uppercase text-white">
                            <span class="text-orange-400">Tienda</span> <span class="text-sky-400">Cartográfica</span>
                        </h1>
                    </section>
                    <section class=" rounded-xl bg-gray-500 relative overflow-hidden">
                        <img class="absolute w-full h-full object-cover z-0" :src="headerMaps" alt="">
                        <div class="bg-gradient-to-b from-gray-800/80 to-gray-800/50 p-6 relative w-full flex flex-col gap-6 items-center z-10 text-center ">
                            <div class="inline-flex w-full max-w-[500px] bg-white relative rounded-full overflow-hidden">
                                <form class="w-full" @submit="onSearch" >
                                <div class="absolute left-0 top-0 h-full w-10 flex items-center justify-center">
                                    <SearchIcon/>
                                </div>
                                <input class="w-full px-10 py-2 outline-0" type="text" v-model="searchWords" placeholder="Busqueda por nombre de mapa" >
                                <div class="absolute right-0 top-0 h-full text-sky-700 w-10 flex items-center justify-center">
                                    <a v-show="searchWords" @click="onCleanSearch" class="text-gray-600 cursor-pointer">
                                       <XCircleIcon />
                                   </a>
                                   <button v-show="!searchWords">
                                       <ArrowRightIcon />
                                   </button>
                                </div>
                                </form>
                            </div>
                            <div class="overflow-auto w-full">
                                <section class="tags flex items-end flex-nowrap md:flex-wrap  justify-start md:justify-center w-fit" >
                                    <button :class="{ '!bg-orange-200  !text-orange-900': selectedSerie==='' }" class="text-xs px-3 py-2 md:py-1 bg-white/90 w-fit whitespace-nowrap overflow-hidden text-gray-800 font-semibold rounded-full mr-1 mb-2" @click="onFilterBySerie('')">
                                     Todos
                                  </button>
                                  <button v-for="(item, key) in saleSeries" :class="{ '!bg-orange-200  !text-orange-900': selectedSerie===item.id }" :key="key" class="text-xs px-3 py-2 md:py-1 bg-white/90 w-fit whitespace-nowrap overflow-hidden text-gray-800 font-semibold rounded-full mr-1 mb-2" @click="onFilterBySerie(item.id)">
                                      {{ item.title }}
                                  </button>
                            </section>
                            </div>
                        </div>
                    </section>
                </header>
                <main class="w-full max-w-[1400px] min-h-[500px] mx-auto grid grid-cols-1 md:grid-cols-4 px-3 pb-10">
                    <div :class="[mainClass]" class="order-2 md:order-1">
                        <section class="h-fit" v-for="(serie, index) in productsBySeries" :key="index">
                            <div class="w-full backdrop-blur bg-white/70 py-3 px-2 sticky top-0 z-20">
                                <h2 class="text-xl font-bold">{{serie.title}}</h2>
                            </div>
                            <div  class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-2 relative">
                                <CartoProductItem
                                    v-for="(product, key) in serie.products"
                                    :key="key"
                                    :product="product"
                                    @onAdd="addItemToShoppingCart(product)"
                                />
                            </div>
                        </section>
                        <CartoProductSkeleton v-if="requestStatus==='loading'" />
                    </div>
                    <aside class="h-full order-1 md:order-2" >
                        <CartSidebar
                            v-if="cartItems.length>0 || !hideShoppingCart"
                            :cart-name="cartName"
                            :cart-items="cartItems"
                            @didChangeCart="getShoppingCartItems"
                            @onClickCompleteShopping="goToCreditCardForm"
                            :is-checkout-view = "false"
                            :show-back-button="true"
                        />
                    </aside>
                </main>
                <Footer />
            </div>
        </div>

        <div v-if="!serviceEnabled" class="h-screen w-screen flex justify-between relative">
            <ProductOrServiceDisabled />
        </div>
    </main>
</template>
<script>
import logo from "@/assets/img/logo.png";
import headerMaps from "@/assets/img/header_maps.jpg";
import Api from '@/services/api'
import CartoProductItem from "@/components/CartoProductItem.vue";
import CartSidebar from "@/components/CartSidebar.vue";
import {getObjectFromLocalStorage, setObjectToLocalStorage} from "@/shared/utils";
import Footer from "@/components/Footer.vue";
import ProductOrServiceDisabled from "@/components/ProductOrServiceDisabled.vue";
import {ServiceAndProductCode, ShoppingCartName} from "@/constants";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import ArrowRightIcon from "@/components/icons/ArrowRightIcon.vue";
import FlatButton from "@/components/controls/FlatButton.vue";
import CartoProductSkeleton from "@/components/CartoProductSkeleton.vue";
import XCircleIcon from "@/components/icons/XCircleIcon.vue";

export default {
      name: 'CartoProductView',
      components: {
          XCircleIcon,
          CartoProductSkeleton,
          FlatButton,
          ArrowRightIcon,
          SearchIcon,
          ProductOrServiceDisabled,
          Footer,
          CartoProductItem,
          CartSidebar
      },
      data () {
        return {
            serviceEnabled: true,
            hideShoppingCart: true,
            logo,
            headerMaps,
            cartName:ShoppingCartName.CARTO_IMAGE,
            cartItems: [],
            selectedSerie: '',
            searchWords: '',
            productsBySeries: [],
            saleSeries: [],
            requestStatus: "loading",
        }
      },
      computed: {
          mainClass(){
              return {
                  "col-span-4 md:col-span-3": this.cartItems.length !== 0 || !this.hideShoppingCart,
                  "col-span-4 md:col-span-4": this.cartItems.length === 0 && this.hideShoppingCart,
              }
          }
      },
      created () {
          document.title = "Tienda Cartográficos";
      },
      mounted () {
          this.getProducsAndServiceAvalaibleByCode()
          this.getProductsFromApi()
          this.getSaleSeriesFromApi()
          this.getShoppingCartItems()
      },
      methods: {
            async getProducsAndServiceAvalaibleByCode () {
                const {data} = await Api.getProducsAndServiceAvalaibleByCode(ServiceAndProductCode.CARTO_IMAGE)
                const {active} = data
                if (active != null){
                    this.serviceEnabled = active
                }
            },
            async getProductsFromApi () {
                this.requestStatus = "loading"
                this.productsBySeries = []
                const {error, errorMessage, data} = await Api.getProductsFromApi(this.searchWords, this.selectedSerie)
                if (error){
                    this.requestStatus = "failed"
                    this.showErrorMessage(errorMessage)
                    return
                }

                this.productsBySeries = data
                this.requestStatus = "success"
            },
            async getSaleSeriesFromApi () {

                const {error, errorMessage, data} = await Api.getSaleSeriesFromApi()
                if (error){
                    this.showErrorMessage(errorMessage)
                    return
                }

                this.saleSeries = data
            },
            onCleanSearch() {
                this.searchWords = ''
                this.getProductsFromApi()
            },
            onSearch(evt){
                evt.preventDefault()
                this.getProductsFromApi()
            },
            onFilterBySerie(id){
                this.selectedSerie = id
                this.getProductsFromApi()
            },
            addItemToShoppingCart (product) {
                  let cartItems = getObjectFromLocalStorage(this.cartName);
                  let existingItem = cartItems.find(item => item.id === product.id);

                  if (!existingItem) {
                      cartItems.push({ id: product.id, title: product.title, files: product.files, sale_type: product.sale_type, price: product.price, quantity: 1, inventories:[], delivery_branch: 0, picker: false, picker_id:'', picker_name:'' });
                  } else {
                      // If the product is already in the cart, increment its quantity
                      if (existingItem.sale_type !== 'digital') {
                          existingItem.quantity += 1;
                      }
                  }

                  setObjectToLocalStorage(this.cartName, cartItems)

                  this.$toast.open({
                      message: 'El producto se agregó al carrito correctamente',
                      type: 'success',
                      position: 'top-right',
                  })
                  this.getShoppingCartItems()
            },
            getShoppingCartItems () {
                if (getObjectFromLocalStorage(this.cartName) != null) {
                    this.cartItems = getObjectFromLocalStorage(this.cartName)
                }
            },
            goToCreditCardForm(){
                this.$router.push(`/formulario-de-compra/${this.cartName}`)
            },
            showErrorMessage(message) {
                this.$toast.open({
                      message: message,
                      type: 'error',
                      position: 'top-right',
                })
            }
      },

}
</script>