<template>
    <div class="bg-white/70 backdrop-blur rounded-xl shadow">
        <ul class="py-2 px-3 bg-white/30 rounded-t-xl">
            <li v-for="(value, key) in propertiesOfLayer" :key="key" v-show="key!=='bbox' && key!=='layer'">
                <strong >{{key}}: </strong><span>{{value}}</span>
            </li>
        </ul>
        <footer class="p-2  flex justify-center space-x-2">
            <FlatButton size="md" width="fit" color="green" v-for="(value, key) in propertiesOfLayer" :key="key" v-show="key==='Codigo_Hoja'"  @click="$emit('didClickAddButton', propertiesOfLayer)">Agregar</FlatButton>
            <FlatButton size="md" width="fit" color="gray"  @click="$emit('didClickCloseButton')">Cerrar</FlatButton>
        </footer>
    </div>
</template>
<script>
import FlatButton from "@/components/controls/FlatButton.vue";

export default {
    components: {FlatButton},
    props: {
        propertiesOfLayer: {
            type: Array,
            required: true
        }
    }
};
</script>
