<template>
    <div :class="symbologyBoxClass" class="shadow bg-white/80 backdrop-blur rounded-xl overflow-hidden min-w-[150px] max-w-[200px] pointer-events-auto" >
        <header>
            <button @click="$emit('didCollapseBox')" class="px-3 py-2 flex justify-between items-center w-full">
                <strong>Simbologia</strong>
                <i :class="symbologyIconClass"></i>
            </button>
        </header>
        <main class="px-3 py-2 bg-white max-h-[300px] overflow-auto">
            <ul class="symbology-content" >
                <li v-for="(symbology, key) in symbologyUrls" :key="key">
                    <img :src="symbology.src" alt=""/>
                </li>
            </ul>
        </main>
    </div>
</template>
<script>
export default {
    props: {
        symbologyUrls: {
            type: Array,
            required: true
        },
        isCollapsed: {
            type: Boolean,
            required: false
        }
    },
    computed:{
        symbologyBoxClass() {
            return {
                'h-10': this.isCollapsed,
            }
        },
        symbologyIconClass(){
            return this.isCollapsed ? 'fa fa-chevron-up': 'fa fa-chevron-down'
        }
    }
};
</script>
