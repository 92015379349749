export const GEO_PROJ = 'EPSG:4326'
export const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://servicios.ign.hn'
export const geoserverUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:9090' : 'https://ign.hn'
export const defaultWorkspace = process.env.NODE_ENV === 'development' ? 'DGCG' : 'DGCG'
export const emergencyWorkspace = process.env.NODE_ENV === 'development' ? 'EMERGENCY' : 'EMERGENCY'

export const ServiceAndProductCode = {
  LAYER: 'layer',
  SHEET: 'sheet',
  CARTO_IMAGE: 'image',
  GEODETIC: 'geodetic_point',
  CENTER_POINT: 'education_center_point',
  AERIAL_PHOTO: 'aerial_photography',
  CADASTRAL_MAINTENANCE: 'cadastral_maintenance',
  SHOPPING_FORM: 'shopping_form',
  EMERGENCY: 'emergency_geoportal',
};
export const ShoppingCartName = {
  LAYER: 'cartGeoportalItems',
  SHEET: 'cartSheetItems',
  CARTO_IMAGE: 'cartCatalogueItems',
  GEODETIC: 'cartGeodeticPoint',
  CENTER_POINT: 'cartPointEducationCenter',
  AERIAL_PHOTO: 'cartAerialPhotography',
  CADASTRAL_MAINTENANCE: 'cartCadastralMaintenance'
};
export const TYPE_IDS= [
    {'id': 'identity', 'title': 'Cédula de Identidad'},
    {'id': 'passport', 'title': 'Pasaporte'},
    {'id': 'rtn', 'title': 'RTN'},
]

export const HttpRequestStatus = {
  INITIAL: 'initial',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const GeoportalName = {
  MAIN: 'main',
  EMERGENCY: 'emergency',
};