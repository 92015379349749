<template>
    <div class="flex relative" :class="[fieldClass]">
        <span class="
            flex
            items-center
            justify-center
            z-10
            absolute
            w-8
            h-full
            text-gray-300
            bg-transparent
            pl-2
            pointer-events-none
        " v-if="cardformat==='number'">
            <i :class="cardBrandClass"></i>
        </span>
        <input
            v-if="cardformat==='number'"
            type="tel"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :name="autocomplete"
            :id="autocomplete"
            required
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            v-cardformat:formatCardNumber
            class="
                pl-10
                placeholder-gray-300
                border-2 border-gray-200
                bg-white rounded
                text-md text-gray-500
                focus:outline-sky-500
                w-full h-full px-3
        "/>
        <input
            v-if="cardformat==='expiry'"
            type="text"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :name="autocomplete"
            :id="autocomplete"
            maxlength="10"
            required
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            v-cardformat:formatCardExpiry
            class="
                placeholder-gray-300
                border-2 border-gray-200
                bg-white rounded
                text-md text-gray-500
                focus:outline-sky-500
                w-full h-full px-3
        "/>
        <input
            v-if="cardformat==='cvv'"
            type="text"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :name="autocomplete"
            :id="autocomplete"
            required
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            v-cardformat:formatCardCVC
            class="
                placeholder-gray-300
                border-2 border-gray-200
                bg-white rounded
                text-md text-gray-500
                focus:outline-sky-500
                w-full h-full px-3
        "/>
    </div>
</template>
<script>
export default {
    emits: ['update:modelValue'],
    props: {
        cardformat: {
          type: String,
          required: true
        },
        width: {
          type: String,
          default: "full"
        },
        size: {
          type: String,
          default: "md"
        },
        placeholder: {
          type: String,
          default: ""
        },
        autocomplete: {
          type: String,
          default: ""
        },
        modelValue: String
    },
    data() {
        return {
            cardBrand: null,
        }
    },
    methods: {
        getBrandClass: function (brand) {
          let icon = '';
          brand = brand || 'unknown';
          let cardBrandToClass = {
            'visa': 'fab fa-cc-visa',
            'mastercard': 'fab fa-cc-mastercard',
            'amex': 'fab fa-cc-amex',
            'discover': 'fab fa-cc-discover',
            'diners': 'fab fa-cc-diners-club',
            'jcb': 'fab fa-cc-jcb',
            'unknown': 'fa fa-credit-card',
          };
          if (cardBrandToClass[brand]) {
            icon = cardBrandToClass[brand];
          }

          return icon;
        }
    },
    computed: {
        cardBrandClass(){
          return this.getBrandClass(this.cardBrand);
        },
        fieldClass() {
            return {
                "w-full": this.width === "full",
                "w-fit": this.width === "fit",

                "h-8": this.size === "sm",
                "h-11": this.size === "md",
                "h-14": this.size === "lg",
            };
        }
    }
};
</script>
