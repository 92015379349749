<template>
    <ul class=" w-full inline-flex overflow-auto">
        <li class="mr-2" v-for="(option, index) in options" :key="index">
            <input type="radio" :id="'option_' + parentIndex + index" :value="option.id" v-model="selectedBranches" class="hidden peer" required :disabled="option.in_stock === 0" >
            <label :for="'option_' + parentIndex + index" class="inline-flex items-center h-full  w-[300px] p-5 text-gray-700 bg-white border border-gray-200 rounded-lg cursor-pointer   peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 peer-disabled:opacity-60 peer-disabled:cursor-not-allowed">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 mr-3" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                       <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                       <path d="M3 21l18 0"></path>
                       <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4"></path>
                       <path d="M5 21l0 -10.15"></path>
                       <path d="M19 21l0 -10.15"></path>
                       <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4"></path>
                    </svg>
                </div>
                <div class="block">
                    <div class="w-full text-lg font-semibold" >{{option.title}}</div>
                    <div v-show="option.in_stock !== 0">
                        <small class="w-full line-clamp-2">{{option.address}}</small>
                        <small class="w-full line-clamp-2">Abre a las {{option.opening_hour}}a.m.</small>
                    </div>
                    <div v-show="option.in_stock === 0">
                        <small class="w-full line-clamp-2">Producto sin existencia en esta sucursal</small>
                    </div>
                </div>
            </label>
        </li>
        <li class="p-6 w-full flex flex-col items-center text-center text-gray-400" v-if="options.length===0">
            <svg xmlns="http://www.w3.org/2000/svg" class="inline-flex" width="40" height="40" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
               <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
               <path d="M15 15m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
               <path d="M18.5 18.5l2.5 2.5"></path>
               <path d="M4 6h16"></path>
               <path d="M4 12h4"></path>
               <path d="M4 18h4"></path>
            </svg>
            <span class="inline-flex max-w-xl">Actualmente, no encontramos sucursales disponibles en tu ubicación o es posible que aún no hayas seleccionado tu departamento.</span>
        </li>
    </ul>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String, // or whichever data type you expect
      required: true,
    },
      parentIndex: {
      type: Number, // or whichever data type you expect
      required: true,
    },
  },
  data() {
    return {
      selectedBranches: this.value,
    };
  },
  watch: {
    selectedBranches(newVal) {
      this.$emit('update:modelValue', newVal)
    },
  },
};
</script>