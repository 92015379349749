/**
 * Devuelve un Objeto del LocalStorage del Navegador
 */
export function getObjectFromLocalStorage (keyString) {
    var retrievedObject = localStorage.getItem(keyString)
    return JSON.parse(retrievedObject) || []
}

/**
 * Guarda un Objeto en el LocalStorage del Navegador
 */
export function setObjectToLocalStorage (keyString, object) {
    localStorage.setItem(keyString, JSON.stringify(object))
}

export function getCookie(cookieName) {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim(); // Remove leading/trailing spaces
        const [name, value] = cookie.split('=');

        if (name === cookieName) {
            return decodeURIComponent(value); // Decoding in case of special characters
        }
    }

    return null; // Return null if the cookie is not found
}

export function parseTextToJsonArray(text) {
    try {
        const cleanedText = text
        .replace(/\\(\d{3})/g, (match, octal) => String.fromCharCode(parseInt(octal, 8)))
        .replace(/'/g, '"');

        return JSON.parse(JSON.parse(cleanedText));
    } catch (error) {
        console.error("Error parsing JSON:", error);
        return null; // Return null or handle the error as needed
    }
}
  