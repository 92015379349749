<template>
<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-layout-collage" width="40" height="40" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
   <path d="M10 4l4 16"></path>
   <path d="M12 12l-8 2"></path>
</svg>
</template>
<script>
export default {
    props: {
    }
};
</script>
