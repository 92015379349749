<template>
<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-live-view" width="40" height="40" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                               <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                               <path d="M4 8v-2a2 2 0 0 1 2 -2h2"></path>
                               <path d="M4 16v2a2 2 0 0 0 2 2h2"></path>
                               <path d="M16 4h2a2 2 0 0 1 2 2v2"></path>
                               <path d="M16 20h2a2 2 0 0 0 2 -2v-2"></path>
                               <path d="M12 11l0 .01"></path>
                               <path d="M12 18l-3.5 -5a4 4 0 1 1 7 0l-3.5 5"></path>
                            </svg>
</template>
<script>
export default {
    props: {
    }
};
</script>
