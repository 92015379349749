<template>
    <div class="relative" :class="[fieldClass]">
        <span class="
            flex
            items-center
            justify-center
            z-10
            absolute
            right-0
            w-8
            h-full
            text-gray-300
            bg-transparent
            pointer-events-none
        ">
            <i class="fas fa-chevron-down"></i>
        </span>
        <select
            class="
                border-2 border-gray-200
                bg-white rounded
                text-md text-gray-500
                focus:outline-sky-500
                appearance-none
                h-full w-full
                pr-8
                px-3
            "
            :required="isRequired"
            @change="onChange"
        >
            <option value="">{{placeholder}}</option>
            <option v-for="option in options" v-bind:value="option.id" :key="option.id">{{ option.title }}</option>
        </select>
    </div>
</template>
<script >
export default {
    props: {
        width: {
          type: String,
          default: "full"
        },
        size: {
          type: String,
          default: "md"
        },
        placeholder: {
          type: String,
          default: ""
        },
        isRequired: {
          type: Boolean,
          default: false
        },
        options: {
          type: [Array,Object],
          required: true
        },
        modelValue: String
    },
    computed: {
        fieldClass() {
            return {
                "w-full": this.width === "full",
                "w-fit": this.width === "fit",

                "h-8": this.size === "sm",
                "h-11": this.size === "md",
                "h-14": this.size === "lg",

                "[&>span]:hidden": this.icon === "none",
                "[&>input]:pl-10": this.icon !== "none",
            };
        }
    },
    methods: {
        onChange({target}) {
            const {selectedIndex} = target;
            let selectedValue = ''
            if (selectedIndex>0){
                selectedValue = this.options[selectedIndex-1]
            }
            this.$emit('update:modelValue', selectedValue)
        }
    }
};
</script>
