<template>
    <button
        :class="[colorVariants, sizeVariant, btnClass]"
        class="
            text-center
            transition-all
            uppercase
            text-white
            font-bold
            outline
            hover:outline-4
            hover:scale-105
            active:scale-95
            outline-transparent
        "
    >
            <slot />
    </button>
</template>
<script>
export default {
    props: {
        color: {
          type: String,
          default: "green"
        },
        size: {
          type: String,
          default: "green"
        },
        width: {
          type: String,
          default: "full"
        },
        noRounded: {
          type: Boolean,
          default: false
        },
    },
    computed: {
        btnClass() {
            return {
                "w-full": this.width === "full",
                "w-fit": this.width === "fit",
                "rounded": !this.noRounded,
            };
        },
        colorVariants() {
            switch (this.color) {
                case "green":
                    return "bg-green-700 hover:bg-green-700/90 hover:outline-green-700/20 active:outline-green-700/20"
                case "sky":
                    return "bg-sky-600 hover:bg-sky-600/90 hover:outline-sky-600/20 active:outline-sky-600/20"
                case "gray":
                    return "bg-gray-600 hover:bg-gray-600/90 hover:outline-gray-600/20 active:outline-gray-600/20"
                default:
                    return 'bg-gray-600'
            }
        },
        sizeVariant() {
            switch (this.size) {
                case "sm":
                    return "text-xs py-1 px-2"
                case "md":
                    return "text-sm py-2 px-3"
                case "lg":
                    return "text-md py-3 px-4"
                case "xl":
                    return "text-lg py-4 px-5"
                default:
                    return 'text-sm py-2 px-3'
            }
        }
    }
};
</script>
