<template>
    <div class="flex relative" :class="[fieldClass]">
        <span class="
            flex
            items-center
            justify-center
            z-10
            absolute
            w-8
            h-full
            text-gray-300
            bg-transparent
            pl-2
            pointer-events-none
        ">
            <i class="fas" :class="icon"></i>
        </span>
        <input
            :type="type"
            :placeholder="placeholder"
            :value="modelValue"
            :min="min"
            :step="step"
            :autocomplete = "autocomplete"
            :required="isRequired"
            @input="$emit('update:modelValue', $event.target.value)"
            class="
                placeholder-gray-300
                border-2 border-gray-200
                bg-white rounded
                text-md text-gray-500
                focus:outline-sky-500
                w-full h-full px-3
        "/>
    </div>
</template>
<script>
export default {
    emits: ['update:modelValue'],
    props: {
        type: {
          type: String,
          default: "text"
        },
        icon: {
          type: String,
          default: "none"
        },
        width: {
          type: String,
          default: "full"
        },
        size: {
          type: String,
          default: "md"
        },
        placeholder: {
          type: String,
          default: ""
        },
        autocomplete: {
          type: String,
          default: ""
        },
        min: {
          type: Number,
          default: 1
        },
        step: {
          type: String,
          default: "any"
        },
        isRequired: {
          type: Boolean,
          default: false
        },
        modelValue: String
    },
    computed: {
        fieldClass() {
            return {
                "w-full": this.width === "full",
                "w-fit": this.width === "fit",

                "h-8": this.size === "sm",
                "h-11": this.size === "md",
                "h-14": this.size === "lg",

                "[&>span]:hidden": this.icon === "none",
                "[&>input]:pl-10": this.icon !== "none",
            };
        }
    }
};
</script>
