<template>
    <li>
        <a :href="link.url" target="_blank" class="hover:bg-gray-100 hover:rounded-lg border-b border-b-gray-100 grid py-2 grid-cols-5 [&>section]:flex [&>section]:items-center [&>section]:h-28">
            <section class="justify-center col-span-2">
                <img :src="link.logo" class="max-h-full " :alt="link.title"/>
            </section>
            <section class="justify-center col-span-2">
                <span>{{link.title}}</span>
            </section>
            <section class="justify-end col-span-1 pr-4">
                <i class="fas fa-chevron-right"></i>
            </section>
        </a>
    </li>
</template>
<script>
export default {
    props: {
        link: {
            url: String,
            logo: String,
            title: String,
        }
    }
};
</script>
