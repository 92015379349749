<template>
    <div class="flex flex-col items-center justify-center py-4">
        <img :src="failedImage" alt="Icono de error de respuesta" width="125" >
        <h2 class="text-3xl pt-3">{{title}}</h2>
        <p class="text-base">{{description}}</p>

        <div class="col-span-1 md:col-span-2 flex justify-end items-center space-x-2 mb-10 mt-6">
            <div>
                <FlatButton @click="resetFormRequest" width="fit" color="gray" size="md">Intentar de nuevo</FlatButton>
            </div>
        </div>
    </div>
</template>
<script>
import failedImage from "@/assets/img/ic_failed.png";
import FlatButton from "@/components/controls/FlatButton.vue";
export default {
    components: {FlatButton},
    data() {
        return {
            failedImage,
        }
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        }
    },
    computed: {
        currentYear() {
            return new Date().getFullYear()
        }
    },
    methods: {
        resetFormRequest(){
            this.$emit('didResetFormRequest');
        }
    }
};
</script>
