import axios from 'axios'
import {baseUrl, geoserverUrl, defaultWorkspace, emergencyWorkspace} from '@/constants'
import {getCookie} from '@/shared/utils'

export default {
    getGeoserverUrl () {
        return geoserverUrl
    },
    getDefaultWorkspace () {
        return defaultWorkspace
    },
    getEmergencyWorkspace () {
        return emergencyWorkspace
    },
    getConfig () {
        const accessToken = getCookie('access_token');
        if (!accessToken) {
            return {}
        }

        return {
            headers: {
                Authorization:  `Token ${accessToken}`,
            },
        };
    },
    handleSucessResponse(response){
        let responseData = []
        if (response.data && response.status === 200) {
            responseData = response.data
        }
        return {error: false, errorMessage: '', data: responseData}
    },
    handleErrorResponse(error) {
        let errorMessage = ''
        if (error.response && error.response.status === 0) {
            if (error.response) {
                const { data, status } = error.response
                errorMessage = `Request was made, but the server responded with a status code: ${status} and data: ${data}`
            } else if (error.request) {
                // The request was made, but no response was received
               errorMessage = 'No response received. The request was made, but no response was received.'
            } else {
                // Something happened in setting up the request
                errorMessage = 'Error setting up the request:'
            }
        } else {
            // Handle other errors here
            errorMessage = `An error occurred: ${error}`
        }
        console.error(errorMessage)
        return {error: true, errorMessage: errorMessage, data: []}
    },
    getLayersFromApi (geoportal_name) {
        return axios
            .get(baseUrl + `/api/layers/?geoportal=${geoportal_name}`, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                // handle error
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    getCategoriesFromApi (geoportal_name) {
        return axios
            .get(baseUrl + `/api/categories/?geoportal=${geoportal_name}`, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                // handle error
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    getLinksFromApi () {
        return axios
            .get(baseUrl + '/api/links/', this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                // handle error
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    getAboutUsFromApi () {
        return axios
            .get(baseUrl + '/api/about_us/', this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    searchLocation (word) {
        return axios
            .get(baseUrl + '/api/search/?q=' + word, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    getSheetDetailsFromApi (code) {
        return axios
            .get(baseUrl + '/api/sheets/?code=' + code, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                // handle error
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    getMetadatosFromApi (code) {
        return axios
            .get(baseUrl + '/api/metadatos/?id=' + code, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    getProductsFromApi (words, serie="") {
        return axios
            .get(baseUrl + `/api/carto_products/?search=${words}&serie=${serie}`, this.getConfig())
            .then(response => {
                // handle success
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    getSaleSeriesFromApi () {
        return axios
            .get(baseUrl + '/api/sale_series/', this.getConfig())
            .then(response => {
                // handle success
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    getDepartmentsFromApi () {
        return axios
            .get(baseUrl + '/api/departments/', this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    getMunicipalitiesFromApi (depto_code) {
        return axios
            .get(baseUrl + '/api/municipalities/?search='+depto_code, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                // handle error
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    getBranchesFromApi (depto_id, product_id, product_type) {
        return axios
            .get(baseUrl + `/api/branches/?department=${depto_id}&product_id=${product_id}&product_type=${product_type}`, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    getCadastralMaintenanceQuoteFromApi (id) {
        return axios
            .get(baseUrl + `/api/cadastral_maintenance_quotes/${id}/`, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                // handle error
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    getGeodeticPointQuoteFromApi (id) {
        return axios
            .get(baseUrl + `/api/geodetic_point_quotes/${id}/`, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    sendCadastralMaintenanceRequest (formdata) {
        return axios
            .post(baseUrl + '/api/cadastral_maintenance_requests/', formdata, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                // handle error
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    sendGeodeticPointRequest (formdata) {
        return axios
            .post(baseUrl + '/api/geodetic_point_requests/', formdata, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                // handle error
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    sendEducationCenterRequest (formdata) {
        return axios
            .post(baseUrl + '/api/point_education_center_requests/', formdata, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    sendAerialPhotographyRequest (formdata) {
        return axios
            .post(baseUrl + '/api/aerial_photography_requests/', formdata, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    sendSaleToApi (formdata) {
        return axios
            //.post(apiUrl + '/api/carto_product_invoice/', formdata)
            .post(baseUrl + '/api/sale/', formdata, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    getEducationCenterPointRequest (id){
        return axios
            .get(baseUrl + `/api/point_education_center_requests/${id}/`, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    getAerialPhotographyRequest (id){
        return axios
            .get(baseUrl + `/api/aerial_photography_requests/${id}/`, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    sendSheetsBoughtRequest (formdata) {
        return axios
            .post(baseUrl + '/api/sheets_bought_request/', formdata, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    getProducsAndServicesAvalaibles () {
        return axios
            .get(`${baseUrl}/api/producs_and_services/`, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    getProducsAndServiceAvalaibleByCode (code) {
        const sessionId = getCookie('session_id');
        return axios
            .get(baseUrl + `/api/producs_and_services/?code=${code}&session_id=${sessionId}`, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                // handle error
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
    getLayerFees (layer_id) {
        return axios
            .get( `${baseUrl}/api/layer_fees/${layer_id}/`, this.getConfig())
            .then(response => {
                return this.handleSucessResponse(response)
            })
            .catch(error => {
                // handle error
                return this.handleErrorResponse(error)
            })
            .finally(() => {
                // always executed
            })
    },
}
