<template>
    <div class="w-full flex items-center justify-center">
        <div v-if="serviceEnabled" class="h-fit w-full max-w-[1400px] bg-white shadow" >
            <TopHeader title="FORMULARIO DE SOLICITUD" subtitle="Autorización de Vuelos Aerofotograficos." />
            <hr class="border-gray-100 border mx-5">
            <div v-show="requestStatus === 'success'">
                <SuccessSentView
                    title="Gracias!"
                    description="Sus datos han sido recibidos correctamente"
                    @didResetFormRequest="resetFormRequest"
                />
            </div>
            <div v-show="requestStatus === 'error'">
                <FailedSentView
                    title="Error!"
                    :description=errorMessage
                    @didResetFormRequest="resetFormRequest"
                />
            </div>
            <main v-show="requestStatus === 'initial' || requestStatus === 'loading'" class="px-3 pb-4 pt-6">
                <form v-on:submit="sendRequest" action="#" method="post">
                    <section class="h-fit w-full">
                        <div class="w-full backdrop-blur bg-white/70 py-2 px-2 sticky top-0 z-20">
                            <h2 class="text-xl font-bold">Información Personal</h2>
                        </div>
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-3 p-3">
                            <div class="col-span-1">
                                <label for="" class="p-0">Nombre Completo</label>
                                <InputField
                                    type="text"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Ingrese sus apellidos y nombres o razón social"
                                    icon="none"
                                    v-model="name"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Profesión u Oficio</label>
                                <InputField
                                    type="text"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Ingrese su ocupación"
                                    icon="none"
                                    v-model="occupation"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Domicilio del solicitante</label>
                                <TextInputField
                                    type="text"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Ingrese la dirección de solicitud"
                                    icon="none"
                                    v-model="address"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Hechos y Razones de solicitud</label>
                                <TextInputField
                                    type="text"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Ingrese la razón de solicitud"
                                    icon="none"
                                    v-model="reasonRequest"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Telefono</label>
                                <InputField
                                    type="number"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Ingrese su telefono para contactarlo"
                                    icon="none"
                                    v-model="phone"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Celular</label>
                                <InputField
                                    type="number"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Ingrese su celular para contactarlo"
                                    icon="none"
                                    v-model="mobile"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Correo Electronico</label>
                                <InputField
                                    type="email"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Ingrese correo electrónico"
                                    icon="none"
                                    v-model="email"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Tipo de Identificación</label>
                                <SelectField
                                    width="full"
                                    size="md"
                                    :is-required="false"
                                    placeholder="Seleccione tipo de identificación"
                                    :options="typeIdList"
                                    v-model="typeIdentitySelected"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Identificación</label>
                                <InputField
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Ingrese el numero de identificación"
                                    icon="none"
                                    v-model="identity"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Departamento</label>
                                <SelectField
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Seleccione su departamento"
                                    :options="departments"
                                    @change="onSelectedDepartment"
                                    v-model="department"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Municipio</label>
                                <SelectField
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Seleccione el municipio"
                                    :options="municipalities"
                                    v-model="municipality"
                                />
                            </div>
                        </div>
                    </section>

                    <section class="h-fit w-full">
                        <div class="w-full backdrop-blur bg-white/70 py-2 px-2 sticky top-0 z-20">
                            <h2 class="text-xl font-bold">Información de aeronave </h2>
                        </div>
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-3 p-3">
                            <div class="col-span-1">
                                <label for="" class="p-0">Marca</label>
                                <InputField
                                    type="text"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Marca del aeronave"
                                    icon="none"
                                    v-model="planeBrand"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Tipo</label>
                                <InputField
                                    type="text"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Tipo de aeronave"
                                    icon="none"
                                    v-model="planeType"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Matricula</label>
                                <InputField
                                    type="text"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Matricula de aeronave"
                                    icon="none"
                                    v-model="planePlate"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Año</label>
                                <InputField
                                    type="text"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Año de la aeronave"
                                    icon="none"
                                    v-model="planeYear"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Tipo de camara aerofotográfica</label>
                                <InputField
                                    type="text"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Tipo de camara"
                                    icon="none"
                                    v-model="camera"
                                />
                            </div>
                        </div>
                        <hr class="border-2 border-gray-100" />
                    </section>

                    <section class="h-fit w-full">
                        <div class="w-full backdrop-blur bg-white/70 py-2 px-2 sticky top-0 z-20">
                            <h2 class="text-xl font-bold">Información de vuelo </h2>
                        </div>
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-3 p-3">
                            <div class="col-span-1">
                                <label for="" class="p-0">Area geográfica a fotografiar (m<sup>2</sup>)</label>
                                <InputField
                                    type="text"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Area a fotografiar"
                                    icon="none"
                                    v-model="flyArea"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Altitud del vuelo (m)</label>
                                <InputField
                                    type="text"
                                    width="full"
                                    size="md"
                                    :is-required="true"
                                    placeholder="Altitud del vuelo"
                                    icon="none"
                                    v-model="flyAltitude"
                                />
                            </div>
                            <div class="col-span-1">
                                <label for="" class="p-0">Periodo en el cual se hara la toma</label>
                                <SelectField
                                    width="full"
                                    size="md"
                                    :is-required="false"
                                    placeholder="Seleccione el periodo en el cual se hara la toma"
                                    :options="flyPeriods"
                                    v-model="flyPeriodSelected"
                                />
                            </div>
                        </div>
                        <hr class="border-2 border-gray-100" />
                    </section>

                    <section class="h-fit w-full px-2">
                        <div class="w-full backdrop-blur bg-white/70 py-2 px-2 sticky top-0 z-20">
                            <h2 class="text-xl font-bold">Miembros de la tripulación  </h2>
                        </div>
                        <div class="relative overflow-x-auto border-2 rounded-md border-gray-100">
                            <table class="w-full text-sm text-left text-gray-500">
                                <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            Nombre y Apellidos
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Función
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Nacionalidad
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-white border-b" v-show="crewMemberList.length === 0">
                                        <td colspan="4">
                                            <span class="block text-center my-6">No hay miembros agregados todavia</span>
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b" v-for="(member, key) in crewMemberList" :key="key">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            {{ member.name }}
                                        </th>
                                        <td class="px-6 py-4">
                                            {{ member.position }}
                                        </td>
                                        <td class="px-6 py-4">
                                            {{ member.nationality }}
                                        </td>
                                        <td>
                                            <button type="button" @click="removeMember(key)" class="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-3 py-1.5 text-center">Eliminar</button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr class="bg-gray-50">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            <InputField
                                                type="text"
                                                width="full"
                                                size="md"
                                                :is-required="false"
                                                placeholder="Nombres"
                                                icon="none"
                                                v-model="memberName"
                                            />
                                        </th>
                                        <td class="px-6 py-4">
                                            <InputField
                                                type="text"
                                                width="full"
                                                size="md"
                                                :is-required="false"
                                                placeholder="Función"
                                                icon="none"
                                                v-model="memberPosition"
                                            />
                                        </td>
                                        <td class="px-6 py-4">
                                            <InputField
                                                type="text"
                                                width="full"
                                                size="md"
                                                :is-required="false"
                                                placeholder="Nacionalidad"
                                                icon="none"
                                                v-model="memberNationality"
                                            />
                                        </td>
                                        <td>
                                            <button type="button" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2" @click="addedNewMember">Agregar</button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <hr class="border-2 border-gray-100" />
                    </section>

                    <section class="h-fit w-full px-2">
                        <div class="col-span-2">
                            <label for="" class="p-0">Verificación de seguridad</label>
                            <RecaptchaForm
                                @didRecaptchaVerified="recaptchaVerified"
                                @didRecaptchaError="recaptchaError"
                                @didRecaptchaExpired="recaptchaExpired"
                            />
                        </div>
                        <hr class="border-2 border-gray-100" />
                    </section>

                    <div class="col-span-2 flex text-center justify-center items-center w-full" v-if="requestStatus === 'loading'">
                        <div class="flex flex-col justify-center items-center">
                            <span>Espera un momento estamos procesando tu solicitud</span>
                            <Spinner color="green" />
                        </div>
                    </div>
                    <div v-if="requestStatus !== 'loading'" class="col-span-1 md:col-span-2 flex justify-end items-center space-x-2 mb-10">
                        <div>
                            <FlatButton @click="this.$router.back()" width="fit" size="md" color="gray">Regresar</FlatButton>
                        </div>
                        <div>
                            <FlatButton width="fit" color="green" size="md">Enviar Solicitud</FlatButton>
                        </div>
                    </div>
                </form>
            </main>
            <Footer/>
        </div>
        <div v-if="!serviceEnabled" class="h-fit w-full max-w-[1400px] bg-white shadow">
            <ProductOrServiceDisabled />
        </div>
    </div>
</template>

<script>
import 'alpinejs'
import Api from '@/services/api'
import Footer from "@/components/Footer.vue";
import logo from "@/assets/img/logo.png";
import InputField from "@/components/controls/InputField.vue";
import SelectField from "@/components/controls/SelectField.vue";
import TextInputField from "@/components/controls/TextInputField.vue";
import Spinner from "@/components/Spinner.vue";
import FlatButton from "@/components/controls/FlatButton.vue";
import TopHeader from "@/components/TopHeader.vue";
import FailedSentView from "@/components/FailedSentView.vue";
import SuccessSentView from "@/components/SuccessSentView.vue";
import RecaptchaForm from "@/components/RecaptchaForm.vue";
import {ServiceAndProductCode, TYPE_IDS} from "@/constants";
import ProductOrServiceDisabled from "@/components/ProductOrServiceDisabled.vue";

export default {
    name: 'Geodesicos',
    components: {
        ProductOrServiceDisabled,
        RecaptchaForm,
        SuccessSentView, FailedSentView,
        TopHeader,
        FlatButton, Spinner,
        TextInputField,
        SelectField, InputField,
        Footer
    },
    data() {
        return {
            serviceEnabled: true,
            logo,
            recaptchaToken:null,
            errorMessage: '',
            name: "",
            typeIdentitySelected: '',
            identity: "",
            departmentSelected: {},
            municipalitySelected: {},
            occupation: "",
            reasonRequest: "",
            department: "",
            municipality: "",
            address: "",
            email: "",
            phone: "",
            mobile: "",
            planeBrand:'',
            planeType:'',
            planePlate:'',
            planeYear:'',
            camera:'',
            flyArea:'',
            flyAltitude:'',
            flyPeriodSelected:{},
            requestStatus: 'initial',
            departments: [],
            municipalities: [],
            flyPeriods: [
                {'id': '1h', 'title': '1 hora'},
                {'id': '2h', 'title': '2 horas'},
                {'id': '3h', 'title': '3 horas'},
                {'id': '5h', 'title': '5 horas'},
                {'id': '1d', 'title': '1 dia'},
            ],
            typeIdList: TYPE_IDS,
            crewMemberList: [],
            memberName: null,
            memberPosition: null,
            memberNationality: null
        }
    },
    computed: {},
    mounted() {
        this.getProducsAndServiceAvalaibleByCode()
        this.getDepartmentsFromApi()
    },
    methods: {
        async getProducsAndServiceAvalaibleByCode () {
            const {data} = await Api.getProducsAndServiceAvalaibleByCode(ServiceAndProductCode.AERIAL_PHOTO)
            const {active} = data
            if (active != null){
                this.serviceEnabled = active
            }
        },
        async getDepartmentsFromApi() {
            const {error, errorMessage, data} = await Api.getDepartmentsFromApi()
            if (error){
                 this.showErrorMessage(errorMessage)
                 return
            }
            this.departments = data;
        },
        async getMunicipalitiesFromApi(deptoCode) {
            const {error, data} = await Api.getMunicipalitiesFromApi(deptoCode)
            if (error){
                 return
            }
            this.municipalities = data;
        },
        onSelectedDepartment({target}) {
            const {selectedIndex} = target
            if (selectedIndex > 0){
                const selectedDepartment = this.departments[selectedIndex-1]
                this.getMunicipalitiesFromApi(selectedDepartment.code)
            }else{
                this.municipalities = []
            }
        },
        sendRequest: function (event) {
            event.preventDefault();

            var formdata = new FormData();
            formdata.append('name', this.name);
            formdata.append('occupation', this.occupation);
            formdata.append('address', this.address);
            formdata.append('phone', this.phone);
            formdata.append('mobile', this.mobile);
            formdata.append('email', this.email);
            formdata.append('reason_request', this.reasonRequest);
            formdata.append('identity_type', this.typeIdentitySelected.id);
            formdata.append('identity', this.identity);

            formdata.append('plane_brand', this.planeBrand);
            formdata.append('plane_type', this.planeType);
            formdata.append('plane_plate', this.planePlate);
            formdata.append('plane_year', this.planeYear);
            formdata.append('camera', this.camera);

            formdata.append('fly_area', this.flyArea);
            formdata.append('fly_altitude', this.flyAltitude);
            formdata.append('fly_period', this.flyPeriodSelected.id);

            formdata.append('department', this.department.id);
            formdata.append('municipality', this.municipality.id);

            formdata.append('aircrew_list', JSON.stringify(this.crewMemberList));
            formdata.append('recaptcha_token', this.recaptchaToken);

            if (this.crewMemberList.length === 0){
                this.$toast.open({
                    message: 'Agregue al menos un tripulante al vuelo',
                    type: 'error'
                })
                return
            }

            if (this.recaptchaToken){
                this.sendAerialPhotographyRequest(formdata)
            }else{
                this.$toast.open({
                    message: 'Por favor complete la verificación de seguridad',
                    type: 'error'
                })
            }
        },
        async sendAerialPhotographyRequest(formdata) {
            this.requestStatus = "loading"
            const {error, errorMessage} = await Api.sendAerialPhotographyRequest(formdata)
            if (error){
                this.requestStatus = "error"
                if (errorMessage){
                   this.errorMessage = errorMessage
                }
                return
            }

            this.requestStatus = "success"
            this.cleanForm()
        },
        cleanForm() {
            this.name = "";
            this.occupation = "";
            this.address = "";
            this.reasonRequest = "";
            this.phone = "";
            this.mobile = "";
            this.email = "";
            this.typeIdentitySelected = "";
            this.identity = "";
            this.departmentSelected = {};
            this.municipalitySelected = {};
            this.planeBrand = "";
            this.planeType = "";
            this.planePlate = "";
            this.planeYear = "";
            this.camera = "";
            this.flyArea = "";
            this.flyAltitude = "";
            this.flyPeriodSelected = {};
            this.crewMemberList= [],
            this.memberName= null,
            this.memberPosition= null,
            this.memberNationality= null
            this.recaptchaToken = "";
        },
        addedNewMember() {
            const {memberName, memberPosition, memberNationality} = this;
            if (memberName && memberPosition && memberNationality){
                this.crewMemberList.push(
                    {
                        'id': Math.random,
                        'name': memberName,
                        'position': memberPosition,
                        'nationality': memberNationality,
                    }
                )
            }else{
                this.$toast.open({
                    message: 'Asegurate de Agregar todos los campos necesarios',
                    type: 'error'
                })
            }
        },
        removeMember(index){
            this.crewMemberList.splice(index, 1);
        },
        recaptchaVerified(token) {
            this.recaptchaToken = token;
        },
        recaptchaError(reason) {
           this.$toast.open({
                message: 'Error al obtener recaptcha: ' + reason,
                type: 'error'
           })
        },
        recaptchaExpired(){
            this.recaptchaToken = null;
        },
        resetFormRequest(){
            this.requestStatus = 'initial';
        },
        showErrorMessage(message) {
            this.$toast.open({
                  message: message,
                  type: 'error',
                  position: 'top-right',
            })
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>