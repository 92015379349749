<template>
    <main >
        <div v-if="serviceEnabled" class="h-screen w-screen flex justify-between relative">
            <main class="flex-grow">
                <ol-map class="w-full h-full relative shadow" ref="map" @click="mapClick" :loadTilesWhileAnimating="true"
                    :loadTilesWhileInteracting="true">
                    <ol-view ref="view" :center="center" :rotation="rotation" :zoom="zoom" :projection="projection"/>
                    <ol-tile-layer v-for="layer in baseLayers" :key="layer.name" :title="layer.name" :visible="layer.visible">
                        <component :is="layer.cmp" v-bind="layer"></component>
                    </ol-tile-layer>
                    <ol-attribution-control/>
                    <ol-vector-layer :title="'drawLayer'">
                        <ol-source-vector :projection="projection"></ol-source-vector>

                        <ol-style>
                            <ol-style-stroke color="yellow" :width="2"></ol-style-stroke>
                            <ol-style-fill color="rgba(255,255,255,0.1)"></ol-style-fill>
                            <ol-style-circle :radius="7">
                                <ol-style-fill color="blue"></ol-style-fill>
                            </ol-style-circle>
                        </ol-style>
                    </ol-vector-layer>
                    <ol-overlay v-if="overlayCoords && propertiesOfLayer && draggingMode===false" :key="overlayKey" :position="overlayCoords">
                        <GeoOverlayBox
                            :properties-of-layer="propertiesOfLayer"
                            @didClickAddButton="addSheetToShoppingCart"
                            @didClickCloseButton="overlayCoords = undefined"
                        />
                    </ol-overlay>

                    <component v-for="layer in layers" :ref="layer.id" overlay :is="layer.cmp" :key="layer.id" v-bind="layer" >
                        <component :ref="layer.source.id" :is="layer.source.cmp" v-bind="layer.source">
                        </component>
                    </component>

                    <div class="absolute z-10 top-0 bottom-0 left-0 right-0 flex flex-col pointer-events-none">
                        <header class="top-bar-header w-full bg-white py-2 px-3 flex items-center justify-between shadow pointer-events-auto">
                            <img :src="logo" width="200" height="59" alt="">
                            <div class="relative cart-button">
                                <small class="flex justify-center items-center rounded-full absolute -top-2 -right-2 bg-red-600 text-white w-4 h-4 shadow z-40" v-if="cartItems.length>0">{{cartItems.length}}</small>
                                <FlatButton width="fit" size="md" color="green" @click="showShoppingCart = !showShoppingCart">
                                    <i class="fa fa-shopping-cart"></i>
                                </FlatButton>
                            </div>
                        </header>
                        <main class="overflow-auto  p-3 pb-10 pointer-events-none flex justify-between space-x-3 space-y-0 flex-grow">
                            <section class="buttons-box h-fit flex flex-col-reverse md:flex-col">
                                <div class="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3 mb-0 md:mb-3 mt-3 md:mt-0 transition-all">
                                    <GeoButton @click="centerMap" title="Centrar Mapa">
                                        <CenterIcon />
                                    </GeoButton>
                                    <GeoButton @click="goToHome" title="Ir al Inicio">
                                        <HomeIcon />
                                    </GeoButton>
                                    <GeoButton @click="exportPDF()" title="Imprimir Mapa">
                                        <PrintIcon />
                                    </GeoButton>
                                    <GeoButton :is-active="searchingMode" @click="searchingMode = !searchingMode" title="Realizar una Busqueda">
                                        <SearchIcon />
                                    </GeoButton>
                                    <div v-if="searchingMode">
                                        <SearchLocationBox @didZoomToExtend="zoomToExtend"/>
                                    </div>
                                    <GeoButton :is-active="draggingMode" @click="toogleMeasure()" title="Activar/Desactivar Regla">
                                        <DrawIcon />
                                    </GeoButton>
                                    <GeoButton title="informacion del Portal" @click="showInfoModal = !showInfoModal">
                                        <InfoIcon />
                                    </GeoButton>
                                    <GeoButton @click="showLinksModal = !showLinksModal">
                                        <LinksIcon />
                                    </GeoButton>
                                </div>

                                <div class="flex flex-col space-y-3">
                                    <GeoZoomControl title="informacion del Portal" @didZoomIn="didZoomIn" @didZoomOut="didZoomOut"/>
                                </div>
                            </section>
                            <section class="flex flex-col justify-between items-end h-full space-y-3 space-x-0">
                                <GeoLayerBox :categories="categories" :layers="layers" :map="map" :baseLayers="baseLayers" @didCheckedLayer="didCheckedLayer" @didClickAddToCart="openPeriodSelectorModal"></GeoLayerBox>

                                <GeoSymbologyBox :symbology-urls="symbologyUrls" :is-collapsed="simbologyBoxIsCollapse" @didCollapseBox="simbologyBoxIsCollapse = !simbologyBoxIsCollapse" />
                            </section>
                        </main>
                    </div>

                </ol-map>
                <Modal
                    :title="aboutUs.title"
                    :open="showInfoModal"
                    @didAcceptClick="showInfoModal=!showInfoModal"
                    @didCancelClick="showInfoModal=!showInfoModal"
                >
                    <div class="p-2">
                        <article v-html="aboutUs.message"></article>
                    </div>
                </Modal>
                <Modal
                    title="Enlaces de Interes"
                    :open="showLinksModal"
                    @didAcceptClick="showLinksModal=!showLinksModal"
                    @didCancelClick="showLinksModal=!showLinksModal"
                >
                    <ul class="p-2">
                        <FeaturedLink v-for="(link, key) in links" :key="key" :link="link"/>
                    </ul>
                </Modal>
                <Modal
                    title="Seleccione la suscripción que desea: "
                    :open="showFeeSelectorModal"
                    accept-text="Agregar al Carrito"
                    @didAcceptClick="addItemToShoppingCart"
                    @didCancelClick="showFeeSelectorModal=!showFeeSelectorModal"
                >
                    <FeeSelector  :options="feeList" v-model="layerFeeSelected" :value="0" :status="feeRequestStatus"/>
                </Modal>
                <Modal
                    title="Confirma las hojas cartograficas para la orden"
                    :open="showSheetModal"
                    @didAcceptClick="addItemToShoppingCart(layerSelected)"
                    @didCancelClick="showSheetModal=!showSheetModal"
                >
                    <main v-for="(sheet, key) in layerSelected.sheets" :key="key">
                        <header class="grid grid-cols-2 mb-3">
                            <section>
                                <strong>Codigo: </strong> {{sheet.code}}
                            </section>
                            <section>
                                <strong>Titulo: </strong> {{sheet.title}}
                            </section>
                        </header>


                        <table class="border-collapse border border-gray-200 ">
                            <tr class="bg-gray-200">
                                <th class="border border-gray-200 p-2">Titulo</th>
                                <th class="border border-gray-200 p-2">Formato</th>
                                <th class="border border-gray-200 p-2">Precio</th>
                                <th class="border border-gray-200 p-2">Cantidad</th>
                                <th class="border border-gray-200 p-2">Agregar</th>
                            </tr>
                            <tr v-for="(file, key) in sheet.attach_files" :key="key">
                                <td class="border border-gray-200 p-2">{{file.title}}</td>
                                <td class="border border-gray-200 p-2">{{file.file_format}}</td>
                                <td class="border border-gray-200 p-2">L.{{file.price}}</td>
                                <td class="border border-gray-200 p-2">
                                    <div class="quantity buttons_added">
                                        <input type="button" value="-" class="minus" @click="decreaseQuantity(file)">
                                        <input type="number" v-model="file.quantity" step="1" min="1" max="" name="quantity" title="Qty" class="input-text qty text" size="4" pattern="" inputmode="">
                                        <input type="button" value="+" class="plus" @click="increaseQuantity(file)">
                                    </div>
                                </td>
                                <td class="border border-gray-200 p-2">
                                    <div class="container-checked">
                                        <input class="styled-checkbox" type="checkbox" :checked="file.active" v-model="file.active" v-bind:id="'file-'+key">
                                        <label :for="'file-'+key"></label>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </main>
                </Modal>
            </main>
            <aside class="bg-white w-[300px]" v-if="showShoppingCart">
                <CartSidebar
                    :cart-items="cartItems"
                    :cart-name="this.cartName"
                    @didChangeCart="getShoppingCartItems"
                    @onClickCompleteShopping="goToCreditCardForm"
                    :is-checkout-view="false"
                    :show-back-button="true"
                />
            </aside>
        </div>

        <div v-if="!serviceEnabled" class="h-screen w-screen flex justify-between relative">
            <ProductOrServiceDisabled />
        </div>
    </main>
</template>

<script>
import {ref} from "vue";
import logo from "@/assets/img/logo.png";
import cardLogos from "@/assets/img/logo.card.brands.png";
import facLogo from "@/assets/img/logo.fac.png";
import GeoButton from "@/components/geoportal/GeoButton.vue";
import SearchLocationBox from "@/components/geoportal/SearchLocationBox.vue";
import {fromLonLat} from 'ol/proj'
import GeoZoomControl from "@/components/geoportal/GeoZoomControl.vue";
import {addInteraction, removeInteraction} from "@/shared/draw";
import {convertMapToPDF} from "@/shared/export.pdf";
import Modal from "@/components/layouts/Modal.vue";
import Api from "@/services/api";
import FeaturedLink from "@/components/geoportal/FeaturedLink.vue";
import GeoLayerBox from "@/components/geoportal/GeoLayerBox.vue";
import HomeIcon from "@/components/icons/HomeIcon.vue";
import PrintIcon from "@/components/icons/PrintIcon.vue";
import CenterIcon from "@/components/icons/CenterIcon.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import DrawIcon from "@/components/icons/DrawIcon.vue";
import CartIcon from "@/components/icons/CartIcon.vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";
import LinksIcon from "@/components/icons/LinksIcon.vue";
import GeoSymbologyBox from "@/components/geoportal/GeoSymbologyBox.vue";
import axios from "axios";
import FlatButton from "@/components/controls/FlatButton.vue";
import GeoOverlayBox from "@/components/geoportal/GeoOverlayBox.vue";
import CartSidebar from "@/components/CartSidebar.vue";
import {getObjectFromLocalStorage, setObjectToLocalStorage} from "@/shared/utils";
import ProductOrServiceDisabled from "@/components/ProductOrServiceDisabled.vue";
import {HttpRequestStatus, ServiceAndProductCode, ShoppingCartName, GeoportalName} from "@/constants";
import FeeSelector from "@/components/controls/FeeSelector.vue";

const defaultZoom = 7.8;

export default {
    name: 'Geoportal',
    components: {
        FeeSelector,
        ProductOrServiceDisabled,
        CartSidebar,
        GeoOverlayBox,
        FlatButton,
        GeoSymbologyBox,
        LinksIcon,
        InfoIcon,
        CartIcon,
        DrawIcon,
        SearchIcon,
        CenterIcon,
        PrintIcon,
        HomeIcon,
        GeoLayerBox,
        FeaturedLink,
        Modal,
        GeoZoomControl,
        SearchLocationBox,
        GeoButton,
    },
    setup() {
        const map = ref(null);

        return {map};
    },
    data() {
        return {
            serviceEnabled: true,
            logo,
            cardLogos,
            facLogo,
            projection: 'EPSG:4326',
            defaultZoom,
            zoom: defaultZoom,
            rotation: 0,
            center: [-85.1065435, 14.6799864],
            searchingMode: false,
            draggingMode: false,
            showInfoModal: false,
            showLinksModal: false,
            showSheetModal: false,
            showFeeSelectorModal: false,
            simbologyBoxIsCollapse: false,
            showShoppingCart: false,
            symbologyUrls:[],
            links: [],
            layers: [],
            categories: [],
            cartItems: [],
            layerFeeSelected: null,
            cartName: ShoppingCartName.LAYER,
            geoportalName: GeoportalName.MAIN,
            overlayKey: 0,
            overlayCoords: undefined,
            tooltipCoords: [0, 0],
            propertiesOfLayer: undefined,
            layerSelected: {},
            aboutUs: {
                title: String,
                message: String
            },
            feeRequestStatus: HttpRequestStatus.INITIAL,
            feeList: [],
            baseLayers: [
                {
                    name: 'basic',
                    thumbnail: 'basic.thumbnail.png',
                    cmp: 'ol-source-osm',
                    title: 'Basico',
                    crossOrigin: 'anonymous',
                    visible: false,
                    baseLayer: true
                },
                {
                    name: 'satelite',
                    thumbnail: 'satelite.thumbnail.png',
                    cmp: 'ol-source-xyz',
                    title: 'Satelite',
                    visible: true,
                    baseLayer: true,
                    crossOrigin: 'anonymous',
                    attributions: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the gis User Community',
                    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                },
                {
                    name: 'terrain',
                    thumbnail: 'terrain.thumbnail.png',
                    cmp: 'ol-source-xyz',
                    title: 'Terreno',
                    visible: false,
                    baseLayer: true,
                    crossOrigin: 'anonymous',
                    attributions: '&copy;<a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy;<a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                    url: 'https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}.png'
                }
            ],
        }
    },
    created () {
        document.title = "Subscripciones Geo";
    },
    mounted() {
        this.getProducsAndServiceAvalaibleByCode()
        this.getCategoryLayers()
        this.getLayers()
        this.getLinksFromApi()
        this.getAboutUsFromApi()
        this.getShoppingCartItems()
    },
    watch : {
        cartItems(val) {
            this.showShoppingCart = val.length > 0
        }
    },
    methods: {
        async getProducsAndServiceAvalaibleByCode () {
            const {data} = await Api.getProducsAndServiceAvalaibleByCode(ServiceAndProductCode.LAYER)
            const {active} = data
            if (active != null){
                this.serviceEnabled = active
            }
        },
        async getLinksFromApi() {
            const {error, data} = await Api.getLinksFromApi()
            if (error){
                 return
            }
            this.links = data
        },
        async getAboutUsFromApi() {

            const {error, data} = await Api.getAboutUsFromApi()
            if (error){
                 return
            }
            this.aboutUs = data
        },
        async getCategoryLayers () {
            const {error, data} = await Api.getCategoriesFromApi(this.geoportalName)
            if (error){
                 return
            }
            this.categories = data
        },
        async getLayerFees (id) {
            this.feeList = []
            this.feeRequestStatus = HttpRequestStatus.LOADING
            const {error, data} = await Api.getLayerFees(id)
            if (error){
                this.feeRequestStatus = HttpRequestStatus.LOADING
                return
            }

            this.feeRequestStatus = HttpRequestStatus.SUCCESS
            this.feeList = data
        },
        async getLayers() {
            const {error, errorMessage, data} = await Api.getLayersFromApi(this.geoportalName)
            if (error){
                 this.showErrorMessage(errorMessage)
                 return
            }

            if (!data.map){
                return
            }

            const layers = data.map(item => {
                const {id,uri,title,visible,category,is_salable,price,sale_type, attach_files, in_stock, metadata_link} = item;

                return {
                    code: id,
                    id: uri,
                    title,
                    cmp: 'ol-image-layer',
                    visible,
                    category,
                    is_salable,
                    price,
                    sale_type,
                    attach_files,
                    in_stock,
                    metadata_link,
                    quantity: 1,
                    added_as_sheet: false,
                    sheets: [],
                    source: {
                        id: 'defaultSource',
                        cmp: 'ol-source-image-wms',
                        url: `${Api.getGeoserverUrl()}/geoserver/${Api.getDefaultWorkspace()}/wms`,
                        layers: `${uri}`,
                        tiled: true,
                        format: 'image/png',
                        serverType: 'geoserver',
                        crossOrigin: 'anonymous'
                    }
                };
            });

            layers.forEach(layer => {
                if (layer.visible) {
                    this.renderSymbology(layer, true);
                }

                this.layers.push(layer);
            });
        },
        async getSheetDetailsFromApi (code) {
            const {error, errorMessage, data} = await Api.getSheetDetailsFromApi(code)
            if (error){
                 this.showErrorMessage(errorMessage)
                 return
            }

            if (data.length > 0) {
                return data[0]
            } else {
                return { }
            }
        },
        async addSheetToShoppingCart (sheet) {
            const {Codigo_Hoja: sheet_code, layer} = sheet
            this.layerSelected = {}
            const details = await this.getSheetDetailsFromApi(sheet_code);

            const layersResult = this.layers.filter(item => item.id === layer);
            if (layersResult.length > 0) {
                this.layerSelected = layersResult[0]
                this.layerSelected.added_as_sheet = true
            }

            this.layerSelected.sheets.map((sheet) => {
                sheet.digitalFormat = false
                if (sheet.added_as_sheet) {
                    this.layerSelected.digitalPrice += parseInt(sheet.physicalPrice)
                }
            })

            details.quantity = 1
            this.layerSelected.sheets = []
            this.layerSelected.sheets.push(details)

            this.showSheetModal = true
        },
        getShoppingCartItems () {
            if (getObjectFromLocalStorage(this.cartName) != null) {
                this.cartItems = getObjectFromLocalStorage(this.cartName)
            }
        },
        openPeriodSelectorModal(layer){
            const {code: id} = layer;
            if (id){
                this.getLayerFees(id)

                this.layerSelected = layer
                this.showFeeSelectorModal = true
            }
        },
        addItemToShoppingCart () {
            if (!this.layerFeeSelected){
                this.$toast.open({
                      message: 'Debes seleccionar el periodo de acceso para poder continuar',
                      type: 'error',
                      position: 'top-right',
                })
                return
            }

            this.showFeeSelectorModal = false

            let cartItems = getObjectFromLocalStorage(this.cartName);
            const {code: id, title, quantity, sheets} = this.layerSelected;
            const { id: fee_id, price, title: fee_title } = this.layerFeeSelected;

            let existingItem = cartItems.find(item => item.id === id);
            let files = [
                {
                    file_format: "WMS, WFS",
                    price,
                }
            ]

            if (!existingItem) {
                cartItems.push({ id, title, subtitle: fee_title, files, sale_type: 'digital', price, quantity: parseInt(quantity), sheets, inventories:[], delivery_branch: 0, picker: false, picker_id:'', picker_name:'', fee_id});
            } else {
              // If the product is already in the cart, increment its quantity
                if (existingItem.sale_type !== 'digital') {
                    existingItem.quantity += 1;
                }
            }

            setObjectToLocalStorage(this.cartName, cartItems)

            this.$toast.open({
                  message: 'El producto se agregó al carrito correctamente',
                  type: 'success',
                  position: 'top-right',
            })
            this.getShoppingCartItems()
        },
        didCheckedLayer (evt, layer){
            const {target} = evt;
            const { title} = layer;
            const {checked: isChecked} = target

            const layersInMap = this.map.map.getLayers().getArray();

            for (const l in layersInMap){
                const layerInMap = layersInMap[l];

                if (layerInMap.values_.title === title){
                    layerInMap.setVisible(isChecked);
                }
            }

            this.renderSymbology(layer, isChecked);
        },
        renderSymbology (layer, isVisible) {
            const urlGeoserver = `${Api.getGeoserverUrl()}/geoserver/${Api.getDefaultWorkspace()}/wms`;
            const src = `${urlGeoserver}?TRANSPARENT=true&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetLegendGraphic&EXCEPTIONS=application%2Fvnd.ogc.se_xml&FORMAT=image%2Fpng&LAYER=${layer.id}&legend_options=fontName:Arial;fontAntiAliasing:true;fontColor:0x000033;fontSize:14;bgColor:0xFFFFFF;dpi:80`;

            if (isVisible) {
                const {id, title} = layer;
                this.symbologyUrls.push({
                    id,
                    src,
                    title
                });
            } else {
                this.symbologyUrls = this.symbologyUrls.filter(item => item.id !== layer.id);
            }
        },
        centerMap() {
            this.viewAnimate(this.center, this.defaultZoom)
        },
        zoomToExtend(coordinates) {
            let place = fromLonLat(coordinates, this.projection)
            this.viewAnimate(place, 10)
        },
        didZoomIn() {
            if (this.zoom < 22) {
                this.zoom += 0.5
            }
            this.viewAnimate(this.center, this.zoom)
        },
        didZoomOut() {
            if (this.zoom > 2) {
                this.zoom -= 0.5
            }
            this.viewAnimate(this.center, this.zoom)
        },
        viewAnimate(coordinates, zoom = this.defaultZoom, duration = 2000) {
            let view = this.$refs.view
            view.animate({
                center: coordinates,
                duration: duration,
                zoom: zoom
            })
        },
        goToHome() {
            const {open} = window
            open('http://www.ign.hn', '_blank')
        },
        exportPDF() {
            document.body.style.cursor = 'wait'
            const {map, mapRef} = this.map;

            convertMapToPDF(map, mapRef)
        },
        toogleMeasure() {
            if (this.draggingMode === true) {
                document.body.style.cursor = 'default'
                this.draggingMode = false
                removeInteraction(this.map.map)
            } else {
                addInteraction(this.map.map, 'LineString')
                document.body.style.cursor = 'crosshair'
                this.draggingMode = true
            }
        },
        mapClick (evt) {
            const map = this.map.map;
            const layerList = map.getLayers().getArray();

            layerList.forEach(layer => {
                this.getInfo(evt, layer);
            });
        },
        getInfo(evt, layer){
            const map = this.map.map;
            const view = map.getView();
            const viewResolution = view.getResolution();
            this.propertiesOfLayer = undefined
            this.overlayKey++

            if (this.draggingMode === true){
                return
            }

            if (layer.getVisible() && layer.values_.title !== 'basic' && layer.values_.title !== 'satelite' && layer.values_.title !== 'terrain' && layer.values_.title !== 'vector'){
                try {
                    const source = layer.getSource();
                    const url = source.getFeatureInfoUrl(
                        evt.coordinate,
                        viewResolution,
                        view.getProjection(), {
                            'INFO_FORMAT': 'application/json',
                            'FEATURE_COUNT': 1
                        }
                    );

                    axios.get(url).then((response) => {
                        let features = response.data.features
                        features.map((feature) => {
                            this.propertiesOfLayer = feature.properties
                            this.propertiesOfLayer.layer = (feature.id).split('.')[0]

                            this.overlayCoords = evt.coordinate
                            this.tooltipCoords = evt.coordinate
                        })
                    })
                } catch (err) {
                    //console.log('Ocurrio un error: ' + err)
                }
            }
        },
        goToCreditCardForm(){
            this.$router.push(`/formulario-de-compra/${this.cartName}`)
        },
        showErrorMessage(message) {
            this.$toast.open({
                  message: message,
                  type: 'error',
                  position: 'top-right',
            })
        }
    }
}
</script>

